import { Image, Table, Tbody, Td, Box, Thead, Tr, Text, Tooltip, Select, Button, Icon, useToast, Input, Stack, Modal, ModalFooter, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, useDisclosure, Checkbox } from '@chakra-ui/react';
import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import { DELETE, GET, PUT } from '../../utilities/ApiProvider.js'
import SpacedStack from '../SpacedStack';
import { DeleteIcon } from '@chakra-ui/icons';
import axios from 'axios';
import { BaseURL } from '../../utilities/config.js';
import { Form, Typography } from 'antd';
import { EditOutlined } from '@ant-design/icons';

export default function ListNeighbor() {

    let orgId = localStorage.getItem('orgId');
    const toast = useToast();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const user = useSelector(state => state?.value);
    const [isLoading, setIsLoading] = useState(false);
    const tableHead = [
        'Name',
        'Status',
        'No of Devices',
        'Actions',
    ]
    const [datas, setDatas] = useState([]);
    const [neighborRelData, setNeighborRelData] = useState([]);
    const [selectedIdForUpdate, setSelectedIdForUpdate] = useState(null);

    const [relationshipData, setRelationshipData] = useState({
        originator: "",
        adjacent: "",
        transient: "",
        destination: "",
    });

    const [neighborName, setNeighborName] = useState('');
    const [neighborOrg, setNeighborOrg] = useState(null);
    const [orgDevices, setOrgDevices] = useState('');
    const [neighborAdj, setNeighborAdj] = useState(null);
    const [adjDevices, setAdjDevices] = useState('');
    const [neighborTra, setNeighborTra] = useState(null);
    const [traDevices, setTraDevices] = useState('');
    const [neighborDes, setNeighborDes] = useState(null);
    const [desDevices, setDesDevices] = useState('');

    const getRelations = async () => {
        if (localStorage.getItem('accessToken')) {
            try {
                const res = await GET(`api/v1/worker/vendors/AWS,AZURE,GCP`);
                setNeighborRelData(res);
            } catch (error) {
                toast({
                    position: 'bottom-left',
                    isClosable: true,
                    duration: 5000,
                    status: 'error',
                    description: 'Neighbor Relations not found!',
                });
            }
        } else {

        }
    };

    const getDevicesName = async (value, identifier) => {
        if (localStorage.getItem('accessToken')) {
            try {
                let name = String(value).split('-')[0].trim();
                let id = String(value).split('-')[1].trim();
                const res = await GET(`api/v1/worker/devices/vendor/${name}/config/${id}`);
                https://apigateway.for-the.biz/api/v1/worker/devices/vendor/AWS/config/65ccde9813e2f0174f5d685f
                if (res) {
                    if (identifier === 'org') {
                        setNeighborOrg(res?.devices)
                    } else if (identifier === 'adj') {
                        setNeighborAdj(res?.devices)
                    } else if (identifier === 'tra') {
                        setNeighborTra(res?.devices)
                    } else if (identifier === 'des') {
                        setNeighborDes(res?.devices)
                    } else {

                    }
                }
            } catch (error) {
                toast({
                    position: 'bottom-left',
                    isClosable: true,
                    duration: 5000,
                    status: 'error',
                    description: 'Neighbor Relations not found!',
                });
            }
        } else {

        }
    };

    const getZone = async () => {
        if (localStorage.getItem('accessToken')) {
            try {
                const res = await GET(`api/v1/worker/neighborhood-zone`);
                setDatas(res);
            } catch (error) {
                toast({
                    position: 'bottom-left',
                    isClosable: true,
                    duration: 5000,
                    status: 'error',
                    description: 'Neighbor Relations not found!',
                });
            }
        } else {

        }
    };

    const deleteDevice = async (id) => {
        try {
            const res = await DELETE(`api/v1/worker/neighborhood-zone/${id}`);
            toast({
                position: 'bottom-left',
                isClosable: true,
                duration: 5000,
                status: 'success',
                description: 'Zone Deleted!',
            });
            getZone();
        } catch (error) {
            toast({
                position: 'bottom-left',
                isClosable: true,
                duration: 5000,
                status: 'error',
                description: 'Neighbor Relations not found!',
            });
        }
    };

    const activateZone = async (id) => {
        try {
            const res = await axios.patch(`${BaseURL}api/v1/worker/neighborhood-zone/activate/${id}`);
            toast({
                position: 'bottom-left',
                isClosable: true,
                duration: 5000,
                status: 'success',
                description: 'Zone Activated!',
            });
            getZone();
        } catch (error) {
            toast({
                position: 'bottom-left',
                isClosable: true,
                duration: 5000,
                status: 'error',
                description: 'Neighbor Relations not found!',
            });
        }
    };

    const neighborRelPostHandler = async () => {
        setIsLoading(true);
        let data = {
            name: neighborName,
            tenantId: orgId,
            originatorConfigurationId: relationshipData.originator,
            adjacentConfigurationId: relationshipData.adjacent,
            transientConfigurationId: relationshipData.transient,
            destinationConfigurationId: relationshipData.destination,
            override :selectedIdForUpdate.override,
            trackLineage :selectedIdForUpdate.trackLineage,
            zoneDevices: [{
                deviceDetails: null,
                customerDeviceId: orgDevices,
                neighbourRelation: "originator",
                vendor: String(relationshipData?.originator).split('-')[0].trim(),
            }, {
                deviceDetails: null,
                customerDeviceId: adjDevices,
                neighbourRelation: "adjacent",
                vendor: String(relationshipData?.adjacent).split('-')[0].trim(),
            }, {

                deviceDetails: null,
                customerDeviceId: traDevices,
                neighbourRelation: "transient",
                vendor: String(relationshipData?.transient).split('-')[0].trim(),
            }, {
                deviceDetails: null,
                customerDeviceId: desDevices,
                neighbourRelation: "destination",
                vendor: String(relationshipData?.destination).split('-')[0].trim(),
            }]
        }
        PUT(`api/v1/worker/neighborhood-zone/${selectedIdForUpdate?.id}`, data, { 'Access-Control-Allow-Origin': true, }, false).then(function (response) {
            if (response.status == 200) {
                let res = response.data;
                if (res?.id) {
                    getZone();
                    onClose();
                    toast({
                        position: 'bottom-left',
                        isClosable: true,
                        duration: 5000,
                        status: 'success',
                        description: 'Neighbor Relationship Saved!',
                    });
                }
            } else {
                response.data.errors.forEach(function (error) {
                    toast({
                        position: 'bottom-left',
                        isClosable: true,
                        duration: 5000,
                        status: 'error',
                        description: error.message,
                    });
                })
            }
        });

        setIsLoading(false);
    }

    useEffect(() => {
        getRelations();
        getZone();
    }, [user]);

    useEffect(() => {
        console.log(selectedIdForUpdate)
    }, [selectedIdForUpdate]);

    return (
        <SpacedStack>
            <Table>
                {/* ... Existing table headers */}
                <Thead className='enerpriseRiskTableHead'>
                    <Tr bgColor={'#555555'} border={'1px solid #555555'}>
                        {tableHead.map((v, i) => (
                            <Td key={i} color={'#fff'} fontSize={'14px'} fontWeight={'700'}>
                                {v}
                            </Td>
                        ))} {/* Add a closing parenthesis for the map function */}
                    </Tr>
                </Thead>
                <Tbody className='enterpriseRiskTable'>
                    {datas && datas.length > 0 ? (
                        datas?.map((v, i) => {
                            return (
                                <Tr key={v?._id} fontSize={'14px'}>
                                    <Td padding={'7px 0 7px 10px'}>{v?.name}</Td>
                                    <Td padding={'7px 0 7px 10px'}>{v?.status}</Td>
                                    <Td padding={'7px 0 7px 10px'}>{v?.zoneDevices?.length}</Td>
                                    <Td padding={'7px 0 7px 10px'} fontSize={'20px'}>
                                        <Stack direction="row" alignItems={'center'} gap="10px">
                                            <Button
                                                onClick={() => activateZone(v?.id)}
                                                bg={"primary.100"}
                                                color={"#fff"}
                                                _hover={"none"}
                                                fontWeight={'600'}
                                                fontSize={'13px'}
                                                border={"1px solid gray"}
                                                padding={"0px 10px"}
                                            >Activate</Button>
                                            <Icon as={EditOutlined} cursor={'pointer'} onClick={() => {
                                                setNeighborName(v?.name)
                                                setSelectedIdForUpdate(v)
                                                setOrgDevices(v?.zoneDevices[0]['customerDeviceId']);
                                                setAdjDevices(v?.zoneDevices[1]['customerDeviceId']);
                                                setTraDevices(v?.zoneDevices[2]['customerDeviceId']);
                                                setDesDevices(v?.zoneDevices[3]['customerDeviceId']);
                                                setRelationshipData({
                                                    originator: v?.originatorConfigurationId,
                                                    adjacent: v?.adjacentConfigurationId,
                                                    transient: v?.transientConfigurationId,
                                                    destination: v?.destinationConfigurationId,
                                                })
                                                onOpen();
                                            }} />
                                            <Icon as={DeleteIcon} cursor={'pointer'} onClick={() => deleteDevice(v?.id)} />
                                        </Stack>
                                    </Td>
                                </Tr>
                            )
                        })
                    ) : (
                        <Text fontWeight={'700'} textAlign={'center'} mt={'20px'} fontSize='14px' color={'black'}>
                            No Data Found
                        </Text>
                    )}
                </Tbody>
            </Table>
            <Modal
                isOpen={isOpen}
                onClose={onClose}
                size={'3xl'}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Update Zone</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text mb={"22px"} fontWeight={"600"} fontSize={"25px"}>Neighbor Relationship</Text>
                        <Stack direction={'row'} alignItems={'flex-end'} gap="40px" mb="10px !important" justifyContent={'space-between'} w="100%">
                            <Stack w="50%">
                                <Typography.Text className="formLabel">Name</Typography.Text>
                                <Input value={neighborName} onChange={e => setNeighborName(e.target.value)} />
                            </Stack>
                            <Stack w="25%">
                                <Checkbox defaultChecked={selectedIdForUpdate?.override} value={selectedIdForUpdate?.override} onChange={e => setSelectedIdForUpdate({ ...selectedIdForUpdate, override: e.target.checked })}><Typography.Text style={{ fontSize: '15px' }} className="formLabel">Override / Decom</Typography.Text></Checkbox>
                            </Stack>
                            <Stack w="25%">
                                <Checkbox defaultChecked={selectedIdForUpdate?.trackLineage} value={selectedIdForUpdate?.trackLineage} onChange={e => setSelectedIdForUpdate({ ...selectedIdForUpdate, trackLineage: e.target.checked })}><Typography.Text style={{ fontSize: '15px' }} className="formLabel">Track Lineage</Typography.Text></Checkbox>
                            </Stack>
                        </Stack>
                        <Stack
                            direction={'row'}
                            alignItems={'center'}
                            gap={'20px'}
                            background={'#fff'}
                            borderRadius={'10px'}
                            marginBottom={'20px !important'}
                            spacing={0}
                            flexWrap={"wrap"}
                            width="100%"
                            overflow={'hidden'}
                            className='neighborRelStack'
                        >

                            <Stack w={'48%'}>
                                <Typography.Text className="formLabel">Originator</Typography.Text>
                                <Select
                                    _focusVisible={{ outline: 'none' }}
                                    border={'1px solid #d9d9d9'}
                                    borderRadius={8}
                                    height={'50px'}
                                    onChange={e => {
                                        getDevicesName(e.target.value, 'org');
                                        setRelationshipData({
                                            ...relationshipData,
                                            originator: e.target.value
                                        })
                                    }}
                                >
                                    <option style={{ display: 'none' }} value={''}>{relationshipData?.originator ?? 'Select'}</option>
                                    {
                                        neighborRelData?.length > 0 &&
                                        neighborRelData?.map(val =>
                                            <option w="32%" value={val}>{val}</option>
                                        )
                                    }
                                </Select>
                            </Stack>
                            <Stack w={'48%'}>
                                <Typography.Text className="formLabel">Adjecent</Typography.Text>
                                <Select
                                    _focusVisible={{ outline: 'none' }}
                                    border={'1px solid #d9d9d9'}
                                    borderRadius={8}
                                    height={'50px'}
                                    onChange={e => {
                                        getDevicesName(e.target.value, 'adj');
                                        setRelationshipData({
                                            ...relationshipData,
                                            adjacent: e.target.value
                                        })
                                    }}
                                >
                                    <option style={{ display: 'none' }} value={''}>{relationshipData?.adjacent ?? 'Select'}</option>
                                    {
                                        neighborRelData?.length > 0 &&
                                        neighborRelData?.map(val =>
                                            <option w="32%" value={val}>{val}</option>
                                        )
                                    }
                                </Select>
                            </Stack>
                            <Stack w={'48%'}>
                                <Typography.Text className="formLabel">Transient</Typography.Text>
                                <Select
                                    _focusVisible={{ outline: 'none' }}
                                    border={'1px solid #d9d9d9'}
                                    borderRadius={8}
                                    height={'50px'}
                                    onChange={e => {
                                        getDevicesName(e.target.value, 'tra');
                                        setRelationshipData({
                                            ...relationshipData,
                                            transient: e.target.value
                                        })
                                    }}
                                >
                                    <option style={{ display: 'none' }} value={''}  >{relationshipData?.transient ?? 'Select'}</option>
                                    {
                                        neighborRelData?.length > 0 &&
                                        neighborRelData?.map(val =>
                                            <option w="32%" value={val}>{val}</option>
                                        )
                                    }
                                </Select>
                            </Stack>
                            <Stack w={'48%'}>
                                <Typography.Text className="formLabel">Destination</Typography.Text>
                                <Select
                                    _focusVisible={{ outline: 'none' }}
                                    border={'1px solid #d9d9d9'}
                                    borderRadius={8}
                                    height={'50px'}
                                    onChange={e => {
                                        getDevicesName(e.target.value, 'des');
                                        setRelationshipData({
                                            ...relationshipData,
                                            destination: e.target.value
                                        })
                                    }}
                                >
                                    <option style={{ display: 'none' }} value={''} >{relationshipData?.destination ?? 'Select'}</option>
                                    {
                                        neighborRelData?.length > 0 &&
                                        neighborRelData?.map(val =>
                                            <option w="32%" value={val}>{val}</option>
                                        )
                                    }
                                </Select>
                            </Stack>
                            <Stack
                                direction={'row'}
                                alignItems={'center'}
                                gap={'10px'}
                                background={'#fff'}
                                borderRadius={'10px'}
                                marginBottom={'20px !important'}
                                spacing={0}
                                flexWrap={"wrap"}
                                overflow={'hidden'}
                            >
                                <Text mb={"22px"} fontWeight={"600"} fontSize={"25px"}>Device / Services</Text>
                                <Stack
                                    direction={'row'}
                                    alignItems={'center'}
                                    gap={'20px'}
                                    background={'#fff'}
                                    borderRadius={'10px'}
                                    marginBottom={'20px !important'}
                                    spacing={0}
                                    flexWrap={"wrap"}
                                    width="100%"
                                    overflow={'hidden'}
                                    className='neighborRelStack'
                                >

                                    <Stack w={'48%'}>
                                        <Typography.Text className="formLabel">Originator</Typography.Text>
                                        <Select
                                            _focusVisible={{ outline: 'none' }}
                                            border={'1px solid #d9d9d9'}
                                            borderRadius={8}
                                            height={'50px'}
                                            onChange={e => setOrgDevices(e.target.value)}
                                        >
                                            <option style={{ display: 'none' }} value={''}>{selectedIdForUpdate?.zoneDevices[0]['customerDeviceId'] ?? 'Select'}</option>
                                            {
                                                neighborOrg?.length > 0 &&
                                                neighborOrg?.map(val =>
                                                    <option value={val}>{val}</option>
                                                )
                                            }
                                        </Select >
                                    </Stack >
                                    <Stack w={'48%'}>
                                        <Typography.Text className="formLabel">Adjecent</Typography.Text>
                                        <Select
                                            _focusVisible={{ outline: 'none' }}
                                            border={'1px solid #d9d9d9'}
                                            borderRadius={8}
                                            height={'50px'}
                                            onChange={e => setAdjDevices(e.target.value)}
                                        >
                                            <option style={{ display: 'none' }} value={''}>{selectedIdForUpdate?.zoneDevices[1]['customerDeviceId'] ?? 'Select'}</option>
                                            {
                                                neighborAdj?.length > 0 &&
                                                neighborAdj?.map(val =>
                                                    <option value={val}>{val}</option>
                                                )
                                            }
                                        </Select>
                                    </Stack>
                                    <Stack w={'48%'}>
                                        <Typography.Text className="formLabel">Transient</Typography.Text>
                                        <Select
                                            _focusVisible={{ outline: 'none' }}
                                            border={'1px solid #d9d9d9'}
                                            borderRadius={8}
                                            height={'50px'}
                                            onChange={e => setTraDevices(e.target.value)}
                                        >
                                            <option style={{ display: 'none' }} value={''}>{selectedIdForUpdate?.zoneDevices[2]['customerDeviceId'] ?? 'Select'}</option>
                                            {
                                                neighborTra?.length > 0 &&
                                                neighborTra?.map(val =>
                                                    <option value={val}>{val}</option>
                                                )
                                            }
                                        </Select>
                                    </Stack>
                                    <Stack w={'48%'}>
                                        <Typography.Text className="formLabel">Destination</Typography.Text>
                                        <Select
                                            _focusVisible={{ outline: 'none' }}
                                            border={'1px solid #d9d9d9'}
                                            borderRadius={8}
                                            height={'50px'}
                                            onChange={e => setDesDevices(e.target.value)}
                                        >
                                            <option style={{ display: 'none' }} value={''}>{selectedIdForUpdate?.zoneDevices[3]['customerDeviceId'] ?? 'Select'}</option>
                                            {
                                                neighborDes?.length > 0 &&
                                                neighborDes?.map(val =>
                                                    <option value={val}>{val}</option>
                                                )
                                            }
                                        </Select>
                                    </Stack>

                                </Stack >
                            </Stack >
                        </Stack>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            onClick={() => neighborRelPostHandler()}
                            isLoading={isLoading}
                            bgColor={'#4A4A4A'}
                            color={'#fff'}
                            padding={'12px'}
                            fontSize={'16px'}
                            fontWeight={'500'}
                            border={'2px solid #4A4A4A'}
                            _hover={{
                                bgColor: 'transparent',
                                cursor: 'pointer',
                                color: '#4A4A4A',
                            }}
                        >
                            Update
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>F
        </SpacedStack >
    )
}
