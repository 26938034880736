import { Box, Stack, Text, Heading, Menu, MenuButton, MenuList, MenuItem, Button, Select, Table, Thead, Tr, Td, Tbody, Tooltip } from '@chakra-ui/react';
import { useRef, useState } from 'react'
import LocationHeader from '../components/LocationHeader';
import NotificationIcon from '../components/LocationHeader/NotificationIcon.js';
import SideWrapper from '../components/SideBar/SideWrapper';
import bg1 from '../assets/images/outlook/bg1.svg'
import bg2 from '../assets/images/outlook/bg2.svg'
import bg3 from '../assets/images/outlook/bg3.svg'
import bg4 from '../assets/images/outlook/bg4.svg'
import bg5 from '../assets/images/outlook/bg5.svg'
import bg6 from '../assets/images/outlook/bg6.svg'
import bg7 from '../assets/images/outlook/bg7.png'
import bg8 from '../assets/images/outlook/bg8.png'

const Outllook = () => {

  const [selectedPolicyEnf, setSelectedPolicyEnf] = useState('N')
  const [selectedSummary, setSelectedSummary] = useState(2)

  const defaultData = [
    {
      prev: {
        ingested: '4.2 PB',
        stored: '25 PB',
        cost: '33% of Cloud Spend',
        risk: 'Medium - High',
        img: bg4,
        w: '50%'
      },
      proj: {
        ingested: '2.58 PB',
        stored: '15.12 PB',
        cost: '19.1 % of Cloud Spend',
        risk: 'Low',
        img: bg6,
        w: '20%'
      },
      act: {
        ingested: '2.67 PB',
        stored: '15.23 PB',
        cost: '18% of Cloud Spend',
        risk: 'Low',
        img: bg6,
        w: '20%'
      }
    },
    {
      prev: {
        ingested: '3.8 PB',
        stored: '22.14 PB',
        cost: '34% of Cloud Spend',
        risk: 'High',
        img: bg7,
        w: '70%'
      },
      proj: {
        ingested: '2.12 PB',
        stored: '13.84 PB',
        cost: '16.5% of Cloud Spend',
        risk: 'Low',
        img: bg6,
        w: '20%'
      },
      act: {
        ingested: '2.23 PB',
        stored: '13.91 PB',
        cost: '17% of Cloud Spend',
        risk: 'Low',
        img: bg6,
        w: '20%'
      }
    },
    {
      prev: {
        ingested: '4.04 PB',
        stored: '24.54 PB',
        cost: '38% of Cloud Spend',
        risk: 'Medium - High',
        img: bg4,
        w: '50%'
      },
      proj: {
        ingested: '2.62 PB',
        stored: '14.42 PB',
        cost: '17% of Cloud Spend',
        risk: 'Low - Medium',
        img: bg8,
        w: '35%'
      },
      act: {
        ingested: '2.71 PB',
        stored: '14.93 PB',
        cost: '18% of Cloud Spend',
        risk: 'Low',
        img: bg6,
        w: '20%'
      }
    },
  ];

  const projRef = useRef(null);
  const actRef = useRef(null);

  const [projSelection, setProjSelection] = useState('Regulatory Compliance Status')
  const updatePProjSelection = (val) => {
    setProjSelection(val)
  }

  const [actSelection, setActSelection] = useState('Regulatory Compliance Status')
  const updateActSelection = (val) => {
    setActSelection(val)
  }

  const tableData = [
    {
      flow: '9b748910-3396-4cfa-8d34-f4d9180467e3',
      source: 'Src: 10.0.43.173 - Dst: 10.0.244.121',
      risk: 'Data Privacy - Medium, Data Integrity - High, Hallucination - Medium, Bias Drift - Medium, Compliance - High',
    },
    {
      flow: '4678623a-188a-4659-84d4-df10508a4569',
      source: 'Src: 10.0.3.21 - Dst: 10.0.64.166',
      risk: 'Data Privacy - Medium, Data Integrity - High, Hallucination - Medium, Bias Drift - Medium, Compliance - High',
    },
    {
      flow: '76e4a024-7e84-481c-a9a0-9e8759f46a6e',
      source: 'Src: 10.0.158.94 - Dst: 10.0.185.227',
      risk: 'Data Privacy - Medium, Data Integrity - High, Hallucination - Medium, Bias Drift - Medium, Compliance - High',
    },
    {
      flow: 'eb7cc092-58b6-49e3-aa6c-66747bd00560',
      source: 'Src: 10.0.240.153 - Dst: 10.0.114.183',
      risk: 'Data Privacy - Medium, Data Integrity - High, Hallucination - Medium, Bias Drift - Medium, Compliance - High',
    },
    {
      flow: '81ae4ef7-367b-42ac-addc-6f2e26632c45',
      source: 'Src: 10.0.65.36 - Dst: 10.0.90.91',
      risk: 'Data Privacy - Medium, Data Integrity - High, Hallucination - Medium, Bias Drift - Medium, Compliance - High',
    },
    {
      flow: '94f46413-b6b6-431b-88f3-55592c660ed7',
      source: 'Src: 10.0.48.152 - Dst: 10.0.118.77',
      risk: 'Data Privacy - Medium, Data Integrity - High, Hallucination - Medium, Bias Drift - Medium, Compliance - High',
    },
    {
      flow: '94f46413-b6b6-431b-88f3-55592c660ed7',
      source: 'Src: 10.0.48.152 - Dst: 10.0.118.77',
      risk: 'Data Integrity - Medium, Data Integrity - High, Hallucination - Medium, Bias Drift - Medium, Compliance - High',
    },
    {
      flow: '94f46413-b6b6-431b-88f3-55592c660ed7',
      source: 'Src: 10.0.48.152 - Dst: 10.0.118.77',
      risk: 'Data Integrity - Medium, Data Integrity - High, Hallucination - Medium, Bias Drift - Medium, Compliance - High',
    },
    {
      flow: '94f46413-b6b6-431b-88f3-55592c660ed7',
      source: 'Src: 10.0.48.152 - Dst: 10.0.118.77',
      risk: 'Data Integrity - Medium, Data Integrity - High, Hallucination - Medium, Bias Drift - Medium, Compliance - High',
    },
    {
      flow: '94f46413-b6b6-431b-88f3-55592c660ed7',
      source: 'Src: 10.0.48.152 - Dst: 10.0.118.77',
      risk: 'Data Integrity - Medium, Data Integrity - High, Hallucination - Medium, Bias Drift - Medium, Compliance - High',
    },
    {
      flow: '76e4a024-7e84-481c-a9a0-9e8759f46a6e',
      source: 'Src: 10.0.158.94 - Dst: 10.0.185.227',
      risk: 'Data Privacy - Medium, Data Integrity - High, Hallucination - Medium, Bias Drift - Medium, Compliance - High',
    },
    {
      flow: 'eb7cc092-58b6-49e3-aa6c-66747bd00560',
      source: 'Src: 10.0.240.153 - Dst: 10.0.114.183',
      risk: 'Data Privacy - Medium, Data Integrity - High, Hallucination - Medium, Bias Drift - Medium, Compliance - High',
    },
    {
      flow: '81ae4ef7-367b-42ac-addc-6f2e26632c45',
      source: 'Src: 10.0.65.36 - Dst: 10.0.90.91',
      risk: 'Data Privacy - Medium, Data Integrity - High, Hallucination - Medium, Bias Drift - Medium, Compliance - High',
    },
    {
      flow: '94f46413-b6b6-431b-88f3-55592c660ed7',
      source: 'Src: 10.0.48.152 - Dst: 10.0.118.77',
      risk: 'Data Privacy - Medium, Data Integrity - High, Hallucination - Medium, Bias Drift - Medium, Compliance - High',
    },
    {
      flow: '94f46413-b6b6-431b-88f3-55592c660ed7',
      source: 'Src: 10.0.48.152 - Dst: 10.0.118.77',
      risk: 'Data Integrity - Medium, Data Integrity - High, Hallucination - Medium, Bias Drift - Medium, Compliance - High',
    },
    {
      flow: '94f46413-b6b6-431b-88f3-55592c660ed7',
      source: 'Src: 10.0.48.152 - Dst: 10.0.118.77',
      risk: 'Data Integrity - Medium, Data Integrity - High, Hallucination - Medium, Bias Drift - Medium, Compliance - High',
    },
    {
      flow: '94f46413-b6b6-431b-88f3-55592c660ed7',
      source: 'Src: 10.0.48.152 - Dst: 10.0.118.77',
      risk: 'Data Integrity - Medium, Data Integrity - High, Hallucination - Medium, Bias Drift - Medium, Compliance - High',
    },
    {
      flow: '94f46413-b6b6-431b-88f3-55592c660ed7',
      source: 'Src: 10.0.48.152 - Dst: 10.0.118.77',
      risk: 'Data Integrity - Medium, Data Integrity - High, Hallucination - Medium, Bias Drift - Medium, Compliance - High',
    },
  ]

  return (

    <SideWrapper>
      <Stack w={'full'} margin={'40px 50px 30px 0 !important'}>
        {/* Current Location identifier */}
        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
          <LocationHeader
            forcedText={'Enterprise Data and Security Outlook'}
          />
          <NotificationIcon />
        </Stack>
        <Stack
          direction={'column'}
          gap={'10px'}
          padding={'35px 30px'}
          background={'#fff'}
          borderRadius={'10px'}
          marginBottom={'20px !important'}
          spacing={0}
        >
          <Select onChange={e => setSelectedSummary(e.target.value)} w="fit-content" alignSelf={'center'} color="#8A959E" borderRadius={'8px'} textAlign={'center'} borderColor={'#8A959E'}>
            <option value={2}>Data and Security Cost Summary - Q4FY24(October - Decemeber)</option>
            <option value={1}>Data and Security Cost Summary - Q1FY24(January - March)</option>
            <option value={0}>Data and Security Cost Summary - Q2FY24(April - June)</option>
          </Select>
          <Stack>
            {
              selectedPolicyEnf === 'N' ?
                <CustomBarChart selectedSummary={selectedSummary} defaultData={defaultData} />
                : selectedPolicyEnf === 'Y' ?
                  <Stack w="100%" direction={'row'} justifyContent={'space-between'}>
                    <CustomBarChart selectedSummary={selectedSummary} defaultData={defaultData} />
                    <Box w="32%">
                      <Heading margin={'30px 0'} fontSize={'20px'} fontWeight={'600'}>Projection</Heading>
                      <Box mb="10px !important" bgColor={'#EBF4FF'} padding={'5px 15px'} bgImage={bg1} bgRepeat={'no-repeat'} bgSize={'50%'} bgPos={'-1px'}>
                        <Stack direction={'row'} justifyContent={'space-between'} fontSize={'14px'}>
                          <Text color={'#fff'}>{defaultData[selectedSummary]['proj']['ingested']}</Text>
                          <Text fontWeight={'500'}>Data Ingested</Text>
                        </Stack>
                      </Box>
                      <Box mb="10px !important" bgColor={'#EBF4FF'} padding={'5px 15px'} bgImage={bg2} bgRepeat={'no-repeat'} bgSize={'30%'} bgPos={'-1px'}>
                        <Stack direction={'row'} justifyContent={'space-between'} fontSize={'14px'}>
                          <Text color={'#fff'}>{defaultData[selectedSummary]['proj']['stored']}</Text>
                          <Text fontWeight={'500'}>Data Stored + Retention</Text>
                        </Stack>
                      </Box>
                      <Box mb="10px !important" bgColor={'#EBF4FF'} padding={'5px 15px'} bgImage={bg3} bgRepeat={'no-repeat'} bgSize={'50%'} bgPos={'-1px'}>
                        <Stack direction={'row'} justifyContent={'space-between'} fontSize={'14px'}>
                          <Text color={'#fff'}>{defaultData[selectedSummary]['proj']['cost']}</Text>
                          <Text fontWeight={'500'}>Security/Data Cost</Text>
                        </Stack>
                      </Box>
                      <Box mb="10px !important" bgColor={'#EBF4FF'} padding={'5px 15px'} bgImage={defaultData[selectedSummary]['proj']['img']} bgRepeat={'no-repeat'} bgSize={defaultData[selectedSummary]['proj']['w']} bgPos={'-1px'}>
                        <Stack direction={'row'} justifyContent={'space-between'} fontSize={'14px'}>
                          <Text color={'#fff'}>{defaultData[selectedSummary]['proj']['risk']}</Text>
                          <Text fontWeight={'500'} >Security/Privacy Risk</Text>
                        </Stack>
                      </Box>
                      <Box onClick={() => projRef?.current.click()} cursor="pointer" mb="10px !important" bgColor={'#EBF4FF'} padding={'5px 15px'} bgImage={bg5} bgRepeat={'no-repeat'} bgSize={'75%'} bgPos={'-1px'}>
                        <Stack direction={'row'} justifyContent={'space-between'} fontSize={'14px'}>
                          <Text color={'#fff'}>{projSelection}</Text>
                          <Text fontWeight={'500'}>Compliant</Text>
                        </Stack>
                      </Box>
                      <Menu>
                        <MenuButton visibility="hidden" ref={projRef} as={Button} height="0px" overflow="hiidden"></MenuButton>
                        <MenuList fontSize="14px">
                          <MenuItem onClick={() => updatePProjSelection('NIST AI 100-1 (AI-RMF)')} fontWeight="500" color="#8A959E">NIST AI 100-1 (AI-RMF):<Text fontSize="15px" ml="5px" as="span" color='#0DB131'>Compliant</Text></MenuItem>
                          <MenuItem onClick={() => updatePProjSelection('IST 800-61R2 (Incident Response)')} fontWeight="500" color="#8A959E">NIST 800-61R2 (Incident Response):<Text fontSize="15px" ml="5px" as="span" color='#0DB131'>Compliant</Text></MenuItem>
                          <MenuItem onClick={() => updatePProjSelection('NIST 800-160v2 (Resiliency)')} fontWeight="500" color="#8A959E">NIST 800-160v2 (Resiliency):<Text fontSize="15px" ml="5px" as="span" color='#0DB131'>Compliant</Text></MenuItem>
                          <MenuItem onClick={() => updatePProjSelection('NIST 800-207 (Zero-Trust)')} fontWeight="500" color="#8A959E">NIST 800-207 (Zero-Trust):<Text fontSize="15px" ml="5px" as="span" color='#F55800'>Partially Compliant</Text></MenuItem>
                          <MenuItem onClick={() => updatePProjSelection('NIST Cybersecurity Framework')} fontWeight="500" color="#8A959E">NIST Cybersecurity Framework:<Text fontSize="15px" ml="5px" as="span" color='#F55800'>Partially Compliant</Text></MenuItem>
                          <MenuItem onClick={() => updatePProjSelection('GDPR')} fontWeight="500" color="#8A959E">GDPR:<Text fontSize="15px" ml="5px" as="span" color='#0DB131'>Compliant</Text></MenuItem>
                          <MenuItem onClick={() => updatePProjSelection('HIPAA')} fontWeight="500" color="#8A959E">HIPAA:<Text fontSize="15px" ml="5px" as="span" color='#0DB131'>Compliant</Text></MenuItem>
                          <MenuItem onClick={() => updatePProjSelection('PCI')} fontWeight="500" color="#8A959E">PCI:<Text fontSize="15px" ml="5px" as="span" color='#0DB131'>Compliant</Text></MenuItem>
                        </MenuList>
                      </Menu>
                    </Box>
                    <Box w="32%">
                      <Heading margin={'30px 0'} fontSize={'20px'} fontWeight={'600'}>Actual</Heading>
                      <Box mb="10px !important" bgColor={'#EBF4FF'} padding={'5px 15px'} bgImage={bg1} bgRepeat={'no-repeat'} bgSize={'50%'} bgPos={'-1px'}>
                        <Stack direction={'row'} justifyContent={'space-between'} fontSize={'14px'}>
                          <Text color={'#fff'}>{defaultData[selectedSummary]['act']['ingested']}</Text>
                          <Text fontWeight={'500'}>Data Ingested</Text>
                        </Stack>
                      </Box>
                      <Box mb="10px !important" bgColor={'#EBF4FF'} padding={'5px 15px'} bgImage={bg2} bgRepeat={'no-repeat'} bgSize={'30%'} bgPos={'-1px'}>
                        <Stack direction={'row'} justifyContent={'space-between'} fontSize={'14px'}>
                          <Text color={'#fff'}>{defaultData[selectedSummary]['act']['stored']}</Text>
                          <Text fontWeight={'500'}>Data Stored + Retention</Text>
                        </Stack>
                      </Box>
                      <Box mb="10px !important" bgColor={'#EBF4FF'} padding={'5px 15px'} bgImage={bg3} bgRepeat={'no-repeat'} bgSize={'50%'} bgPos={'-1px'}>
                        <Stack direction={'row'} justifyContent={'space-between'} fontSize={'14px'}>
                          <Text color={'#fff'}>{defaultData[selectedSummary]['act']['cost']}</Text>
                          <Text fontWeight={'500'}>Security/Data Cost</Text>
                        </Stack>
                      </Box>
                      <Box mb="10px !important" bgColor={'#EBF4FF'} padding={'5px 15px'} bgImage={defaultData[selectedSummary]['act']['img']} bgRepeat={'no-repeat'} bgSize={defaultData[selectedSummary]['act']['w']} bgPos={'-1px'}>
                        <Stack direction={'row'} justifyContent={'space-between'} fontSize={'14px'}>
                          <Text color={'#fff'}>{defaultData[selectedSummary]['act']['risk']}</Text>
                          <Text fontWeight={'500'} >Security/Privacy Risk</Text>
                        </Stack>
                      </Box>
                      <Box onClick={() => actRef?.current.click()} cursor="pointer" mb="10px !important" bgColor={'#EBF4FF'} padding={'5px 15px'} bgImage={bg5} bgRepeat={'no-repeat'} bgSize={'75%'} bgPos={'-1px'}>
                        <Stack direction={'row'} justifyContent={'space-between'} fontSize={'14px'}>
                          <Text color={'#fff'}>{actSelection}</Text>
                          <Text fontWeight={'500'}>Compliant</Text>
                        </Stack>
                      </Box>
                      <Menu>
                        <MenuButton visibility="hidden" ref={actRef} as={Button} height="0px" overflow="hiidden"></MenuButton>
                        <MenuList fontSize="14px">
                          <MenuItem onClick={() => updateActSelection('NIST AI 100-1 (AI-RMF)')} fontWeight="500" color="#8A959E">NIST AI 100-1 (AI-RMF):<Text fontSize="15px" ml="5px" as="span" color='#0DB131'>Compliant</Text></MenuItem>
                          <MenuItem onClick={() => updateActSelection('IST 800-61R2 (Incident Response)')} fontWeight="500" color="#8A959E">NIST 800-61R2 (Incident Response):<Text fontSize="15px" ml="5px" as="span" color='#0DB131'>Compliant</Text></MenuItem>
                          <MenuItem onClick={() => updateActSelection('NIST 800-160v2 (Resiliency)')} fontWeight="500" color="#8A959E">NIST 800-160v2 (Resiliency):<Text fontSize="15px" ml="5px" as="span" color='#0DB131'>Compliant</Text></MenuItem>
                          <MenuItem onClick={() => updateActSelection('NIST 800-207 (Zero-Trust)')} fontWeight="500" color="#8A959E">NIST 800-207 (Zero-Trust):<Text fontSize="15px" ml="5px" as="span" color='#F55800'>Partially Compliant</Text></MenuItem>
                          <MenuItem onClick={() => updateActSelection('NIST Cybersecurity Framework')} fontWeight="500" color="#8A959E">NIST Cybersecurity Framework:<Text fontSize="15px" ml="5px" as="span" color='#F55800'>Partially Compliant</Text></MenuItem>
                          <MenuItem onClick={() => updateActSelection('GDPR')} fontWeight="500" color="#8A959E">GDPR:<Text fontSize="15px" ml="5px" as="span" color='#0DB131'>Compliant</Text></MenuItem>
                          <MenuItem onClick={() => updateActSelection('HIPAA')} fontWeight="500" color="#8A959E">HIPAA:<Text fontSize="15px" ml="5px" as="span" color='#0DB131'>Compliant</Text></MenuItem>
                          <MenuItem onClick={() => updateActSelection('PCI')} fontWeight="500" color="#8A959E">PCI:<Text fontSize="15px" ml="5px" as="span" color='#0DB131'>Compliant</Text></MenuItem>
                        </MenuList>
                      </Menu>
                    </Box>
                  </Stack>
                  : null
            }
          </Stack>
        </Stack>
        <Stack
          padding={'35px 30px'}
          background={'#fff'}
          borderRadius={'10px'}
          marginBottom={'20px !important'}
          spacing={0}
          w="100%"
        >
          <Table>
            {/* ... Existing table headers */}
            <Thead className='enerpriseRiskTableHead'>
              <Tr bgColor={'#555555'} border={'1px solid #555555'}>
                <Td textAlign={"center"} color={'#fff'} fontSize={'13px'} fontWeight={'500'}>Flow/Batch ID</Td>
                <Td textAlign={"center"} color={'#fff'} fontSize={'13px'} fontWeight={'500'}>Source - Destination</Td>
                <Td textAlign={"center"} color={'#fff'} fontSize={'13px'} fontWeight={'500'}>Encrypted</Td>
                <Td textAlign={"center"} color={'#fff'} fontSize={'13px'} fontWeight={'500'}>Risk</Td>
                <Td textAlign={"center"} color={'#fff'} fontSize={'13px'} fontWeight={'500'}>Override/Decommission Enabled</Td>
                <Td textAlign={"center"} color={'#fff'} fontSize={'13px'} fontWeight={'500'}>Policy Enforced</Td>
                <Td textAlign={"center"} color={'#fff'} fontSize={'13px'} fontWeight={'500'}>Business Impact</Td>
              </Tr>
            </Thead>
            <Tbody className='enterpriseRiskTable'>
              {
                tableData?.map((val, ind) => {
                  return (
                    <Tr key={ind} textAlign="center">
                      <Td color={'#262626'} fontSize={'14px'} fontWeight={'600'}>
                        <Tooltip label={val?.flow} placement="top" hasArrow>
                          <Box maxW="200px" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
                            {val?.flow}
                          </Box>
                        </Tooltip>
                      </Td>
                      <Td color={'#262626'} fontSize={'14px'} fontWeight={'600'}>
                        <Tooltip label={val?.source} placement="top" hasArrow>
                          <Box maxW="200px" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
                            {val?.source}
                          </Box>
                        </Tooltip>
                      </Td>
                      <Td color={'#262626'} fontSize={'14px'} fontWeight={'600'}>Y</Td>
                      <Td color={'#262626'} fontSize={'14px'} fontWeight={'600'}>
                        <Tooltip label={val?.risk} placement="top" hasArrow>
                          <Box maxW="240px" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
                            {val?.risk}
                          </Box>
                        </Tooltip>
                      </Td>
                      <Td textAlign="center" color={'#262626'} fontSize={'14px'} fontWeight={'600'}><Box>Y</Box></Td>
                      <Td textAlign="center" w="200px" color={'#262626'} fontSize={'14px'} fontWeight={'600'}><Box>
                        <Select onClick={e => { ind === 0 ? setSelectedPolicyEnf(e.target.value) : setSelectedPolicyEnf(selectedPolicyEnf) }}>
                          <option value="N">N</option>
                          <option value="Y">Y</option>
                        </Select>
                      </Box>
                      </Td>
                      <Td color={'#262626'} fontSize={'14px'} fontWeight={'600'}>
                        <Box maxW="200px" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
                          <Text>
                            Cost - High,
                            Securiy - Medium,
                            Compliance - High
                          </Text>
                        </Box>
                      </Td>
                    </Tr>
                  )
                })
              }
            </Tbody>
          </Table>
          <Stack direction={'row'} w="100%" alignItems={'center'} gap="1%" flexWrap={'wrap'} spacing={0}>
          </Stack>
        </Stack>
      </Stack>
    </SideWrapper >
  );
};

export const CustomBarChart = ({ selectedSummary, defaultData }) => {

  const buttonRef = useRef(null);
  const [prevSelection, setPrevSelection] = useState('Regulatory Compliance Status')

  const updatePrevSelection = (val) => {
    setPrevSelection(val)
  }
  console.log(typeof selectedSummary)
  return (
    <Box w="32%">
      <Heading margin={'30px 0'} fontSize={'20px'} fontWeight={'600'}>Previous Month</Heading>
      <Box mb="10px !important" bgColor={'#EBF4FF'} padding={'5px 15px'} bgImage={bg1} bgRepeat={'no-repeat'} bgSize={'50%'} bgPos={'-1px'}>
        <Stack direction={'row'} justifyContent={'space-between'} fontSize={'14px'}>
          <Text color={'#fff'}>{defaultData[selectedSummary]['prev']['ingested']}</Text>
          <Text fontWeight={'500'}>Data Ingested</Text>
        </Stack>
      </Box>
      <Box mb="10px !important" bgColor={'#EBF4FF'} padding={'5px 15px'} bgImage={bg2} bgRepeat={'no-repeat'} bgSize={'30%'} bgPos={'-1px'}>
        <Stack direction={'row'} justifyContent={'space-between'} fontSize={'14px'}>
          <Text color={'#fff'}>{defaultData[selectedSummary]['prev']['stored']}</Text>
          <Text fontWeight={'500'}>Data Stored + Retention</Text>
        </Stack>
      </Box>
      <Box mb="10px !important" bgColor={'#EBF4FF'} padding={'5px 15px'} bgImage={bg3} bgRepeat={'no-repeat'} bgSize={'50%'} bgPos={'-1px'}>
        <Stack direction={'row'} justifyContent={'space-between'} fontSize={'14px'}>
          <Text color={'#fff'}>{defaultData[selectedSummary]['prev']['cost']}</Text>
          <Text fontWeight={'500'}>Security/Data Cost</Text>
        </Stack>
      </Box>
      <Box mb="10px !important" bgColor={'#EBF4FF'} padding={'5px 15px'} bgImage={defaultData[selectedSummary]['prev']['img']} bgRepeat={'no-repeat'} bgSize={defaultData[selectedSummary]['prev']['w']} bgPos={'-1px'}>
        <Stack direction={'row'} justifyContent={'space-between'} fontSize={'14px'}>
          <Text color={'#fff'}>{defaultData[selectedSummary]['prev']['risk']}</Text>
          <Text fontWeight={'500'}>Risk</Text>
        </Stack>
      </Box>
      <Box cursor={'pointer'} onClick={() => buttonRef?.current.click()} mb="10px !important" bgColor={'#EBF4FF'} padding={'5px 15px'} bgImage={bg5} bgRepeat={'no-repeat'} bgSize={selectedSummary === '2' ? '70%' : '70%'} bgPos={'-1px'}>
        <Stack direction={'row'} justifyContent={'space-between'} fontSize={'14px'}>
          <Text color={'#fff'}>{prevSelection}</Text>
          <Text fontWeight={'500'}>{selectedSummary == '2' ? 'Partial Compliant' : 'Non-Compliant'}</Text>
        </Stack>
      </Box>
      <Menu>
        <MenuButton visibility="hidden" ref={buttonRef} as={Button} height="0px" overflow="hiidden"></MenuButton>
        <MenuList fontSize="14px">
          <MenuItem onClick={() => updatePrevSelection('NIST AI 100-1 (AI-RMF)')} fontWeight="500" color="#8A959E">NIST AI 100-1 (AI-RMF):<Text fontSize="15px" ml="5px" as="span" color='#FF0000'>Non-Compliant</Text></MenuItem>
          <MenuItem onClick={() => updatePrevSelection('IST 800-61R2 (Incident Response)')} fontWeight="500" color="#8A959E">NIST 800-61R2 (Incident Response):<Text fontSize="15px" ml="5px" as="span" color='#F55800'>Partially Compliant</Text></MenuItem>
          <MenuItem onClick={() => updatePrevSelection('NIST 800-160v2 (Resiliency)')} fontWeight="500" color="#8A959E">NIST 800-160v2 (Resiliency):<Text fontSize="15px" ml="5px" as="span" color='#F55800'>Partially Compliant</Text></MenuItem>
          <MenuItem onClick={() => updatePrevSelection('NIST 800-207 (Zero-Trust)')} fontWeight="500" color="#8A959E">NIST 800-207 (Zero-Trust):<Text fontSize="15px" ml="5px" as="span" color='#FF0000'>Non-Compliant</Text></MenuItem>
          <MenuItem onClick={() => updatePrevSelection('NIST Cybersecurity Framework')} fontWeight="500" color="#8A959E">NIST Cybersecurity Framework:<Text fontSize="15px" ml="5px" as="span" color='#F55800'>Partially Compliant</Text></MenuItem>
          <MenuItem onClick={() => updatePrevSelection('GDPR')} fontWeight="500" color="#8A959E">GDPR:<Text fontSize="15px" ml="5px" as="span" color='#FF0000'>Non-Compliant</Text></MenuItem>
          <MenuItem onClick={() => updatePrevSelection('HIPAA')} fontWeight="500" color="#8A959E">HIPAA:<Text fontSize="15px" ml="5px" as="span" color='#0DB131'>Compliant</Text></MenuItem>
          <MenuItem onClick={() => updatePrevSelection('PCI')} fontWeight="500" color="#8A959E">PCI:<Text fontSize="15px" ml="5px" as="span" color='#0DB131'>Compliant</Text></MenuItem>
        </MenuList>
      </Menu>
    </Box>
  )
}

export default Outllook;
