import { Box, Stack, Text, Heading, Menu, MenuButton, MenuList, MenuItem, Button, useToast, Input, Image, Spinner, Icon } from '@chakra-ui/react';
import { useState } from 'react'
import LocationHeader from '../components/LocationHeader';
import NotificationIcon from '../components/LocationHeader/NotificationIcon.js';
import SideWrapper from '../components/SideBar/SideWrapper';
import NavGptImg from '../assets/images/logoB.png'
import { BsArrowUp } from 'react-icons/bs'
import { GET, POST } from '../utilities/ApiProvider';
import favIcon from '../assets/images/favicon.svg'

const Policy = () => {

  const toast = useToast();

  const [isLoading, setIsLoading] = useState(false);
  const [questionsArray, setQuestionsArray] = useState([]);
  const [answersArray, setAnswersArray] = useState([]);

  const [navGptQuestion, setNavGptQuestion] = useState('');
  // const [navGptAnswer, setNavGptAnswer] = useState('');

  const submitQuery = async () => {
    setIsLoading(true)
    try {
      let response = await POST(`api/v1/rag1/chat`, { query: navGptQuestion }, {
        headers: {
          "Cache-Control": "no-cache",
          "Content-Type": "application/json",
          "Content-Length": "<calculated when request is sent>",
          "Host": "<calculated when request is sent>"
        },
      }
      );
      if (response?.answer) {
        setQuestionsArray(prev => [...prev, navGptQuestion]);
        setAnswersArray(prev => [...prev, response?.answer]);
        setNavGptQuestion('');
      }
    } catch (err) {
      toast({
        description: err.message,
        status: 'error',
        position: 'top-right',
        isCloseable: true,
        duration: 3000
      })
    }
    setIsLoading(false)
  }

  const submitQueryWithButton = async (defaultQ) => {
    setIsLoading(true)
    try {
      let response = await POST(`api/v1/rag1/chat`, { query: defaultQ }, {
        headers: {
          "Cache-Control": "no-cache",
          "Content-Type": "application/json",
        },
      }
      );
      if (response?.answer) {
        setQuestionsArray(prev => [...prev, defaultQ]);
        setAnswersArray(prev => [...prev, response?.answer]);
        setNavGptQuestion('');
      }
    } catch (err) {
      toast({
        description: err.message,
        status: 'error',
        position: 'top-right',
        isCloseable: true,
        duration: 3000
      })
    }
    setIsLoading(false)
  }

  const handleKeyPress = event => {
    if (event.keyCode === 13) {
      submitQuery();
    }
  };

  const buttonStyles = {
    width: '49%',
    border: '1px solid',
    borderColor: '#1a1a1a',
    borderRadius: '6px',
    height: '50px',
    fontWeight: '600',
    marginBottom: '15px !important',
    textAlign: 'left',
    fontSize: '13px',
    justifyContent: 'flex-start',
  }

  return (

    <SideWrapper>
      <Stack w={'full'} margin={'40px 50px 30px 0 !important'}>
        {/* Current Location identifier */}
        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
          <LocationHeader
            forcedText={'NavGPT'}
          />
          <NotificationIcon />
        </Stack>
        <Stack height="100%">
          {
            answersArray?.length > 0 ?
              <Stack overflowY="auto" height={'700px'} flexDirection="column" justifyContent={'flex-start'} alignItems={'flex-start'} w="100%" padding={'20px 0'}>
                {
                  answersArray?.map((val, ind) => {
                    return (
                      <Box key={ind} w="80% !important" marginX="auto !important">
                        <Box display={'flex'} alignItems="center" gap="20px" mb="20px !important" bgColor="#fff" p="15px" borderRadius={'15px'}>
                          <Heading
                            fontSize="13px"
                            borderRadius="100%"
                            p="12px 6px"
                            border={'2px solid'}
                            borderColor={'primary.100'}
                          >You:</Heading>
                          <Text fontSize="15px">{questionsArray[ind]}</Text>
                        </Box>
                        <Box display={'flex'} alignItems="flex-start" gap="20px" mb="20px !important" bgColor="#fff" p="15px" borderRadius={'15px'}>
                          <Image src={favIcon} w="43px" border="2px solid" borderColor="primary.100" borderRadius="100%" p="6px" />
                          <Text fontSize="15px">{answersArray[ind]}</Text>
                        </Box>
                      </Box>
                    )
                  })
                }
              </Stack>
              :
              <Stack height="70%" flexDirection="row" justifyContent={'center'} alignItems={'center'} w="100%">
                <Box>
                  <Image src={favIcon} margin="0 auto 10px" w="40px" />
                  <Heading fontSize="1.5rem">How can I help you today?</Heading>
                </Box>
              </Stack>
          }
          <Stack height={answersArray?.length > 1 ? '125px' : '30%'} justifyContent="end">
            <Box w={answersArray?.length > 0 ? "52%" : "48rem"} marginX="auto">
              {
                answersArray?.length > 0 ?
                  null :
                  <Stack direction="row" flexWrap="wrap" spacing="0" alignItems="center" justifyContent="space-between">
                    <Button sx={buttonStyles} isDisabled={isLoading} onClick={() => submitQueryWithButton('Data and Security Cost Management')}>Data and Security Cost Management</Button>
                    <Button sx={buttonStyles} isDisabled={isLoading} onClick={() => submitQueryWithButton('Data Integrity and Privacy')}>Data Integrity and Privacy</Button>
                    <Button sx={buttonStyles} isDisabled={isLoading} onClick={() => submitQueryWithButton('Resiliency (MLOPS, DataOps, and ModelOps)')}>Resiliency (MLOPS, DataOps, and ModelOps)</Button>
                    <Button sx={buttonStyles} isDisabled={isLoading} onClick={() => submitQueryWithButton('Regulatory Compliance and Governance')}>Regulatory Compliance and Governance</Button>
                  </Stack>
              }
              <Box w="100%">
                <Box
                  display="flex"
                  w="100% !important"
                  border='1px solid'
                  borderColor='#1a1a1a'
                  borderRadius='6px'
                  height='50px'
                  fontWeight='600'
                  marginBottom='15px !important'
                  textAlign='left'
                  fontSize='13px'
                  justifyContent='flex-start'
                  position="relative"
                >
                  <Input readOnly={isLoading} onKeyDownCapture={handleKeyPress} _focusVisible={{ outline: 'none' }} height="48px" border="none" w="100%" placeholder='Message NavGPT....' value={navGptQuestion} onChange={e => setNavGptQuestion(e.target.value)} />
                  <Button onClick={submitQuery} isLoading={isLoading} height="40px" w="40px" p="0" m="4px 4px 0 0" bgColor="primary.100" color="#fff" _hover={{ bgColor: 'primary.100' }}><Icon as={BsArrowUp} fontSize="24px" /></Button>
                </Box>
                <Text fontSize="12px" textAlign="center">NavGPT can make mistakes, Consider checking important information.</Text>
              </Box>
            </Box>
          </Stack>
        </Stack>
      </Stack>
    </SideWrapper >
  );
};

export default Policy;
