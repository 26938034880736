import { Box, Stack, Text, Heading, Menu, MenuButton, MenuList, MenuItem, Button, useToast, Input, Modal, Checkbox, Select, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, useDisclosure } from '@chakra-ui/react/dist/chakra-ui-react.cjs';
import React from 'react';
import LocationHeader from '../components/LocationHeader';
import NotificationIcon from '../components/LocationHeader/NotificationIcon.js';
import SideWrapper from '../components/SideBar/SideWrapper';
import CloudOptionButton from '../components/CloudComponents/CloudOptionButton';
import { getSupportedVendorById, fetchSupportedVendors, fetchVendorConfigurations, getCloudOptionId } from '../utilities/cloudOnBoardingData';
import { policyCloudOptions } from '../utilities/PolicyUtil';
import { useState } from 'react';
import { desiredOptions, genPolicyData, subCatList } from '../utilities/desiredOutcomesData';
import DesiredButton from '../components/DesiredOutcomes/DesiredButtons';
import { useSelector } from 'react-redux'
import SpacedStack from '../components/SpacedStack';
import { Form, Typography } from 'antd';
import { Enforcement, policyAction, solutionAzureOpt1 } from "../utilities/PolicyUtil";
import HostCloudButton from '../components/CloudComponents/HostCloudButton';
import SolutionSubOptBox from '../components/SolutionBuilder/SolutionSubOptBox';
import { GET, POST } from '../utilities/ApiProvider';
import { useEffect } from 'react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import CreatePolicy from '../components/Policy/CreatePolicy';
import Cron from 'react-cron-generator';

const Policy = () => {
  const orgId = localStorage.getItem('orgId');
  const serviceNowUsers = useSelector(state => state.serviceNowUsers);
  const toast = useToast();
  const [selectedCloudOption, setSelectedCloudOption] = useState('');
  const [selectedDesireOption, setSelectedDesireOption] = useState({});
  const [selectedSubCat, setSelectedSubCat] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [actionSelectedItems, setActionSelectedItems] = useState([]);
  const [enforcementSelectedItems, setEnforcementSelectedItems] = useState([]);
  //  todo   caller_id: "seqoria2@testtest.com",
  const [assignData, setAssignData] = useState({
    type: "INCIDENT",
    active: "true",
    description: "",
    short_description: "",
    assigned_to: "",
    credentials: ""
  });

  const [zones, setZones] = useState([]);
  const [supportedVendors, setSupportedVendors] = useState([]);
  const [generativeAICategories, setGenerativeAICategories] = useState([])
  const [selectedSupportedVendor, setSelectedSupportedVendor] = useState({});

  const [policyName, setPolicyName] = useState("");
  const [policyDescription, setPolicyDescription] = useState("");
  const [hostData, setHostData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedHostOption, setSelectedHostOption] = useState(null);
  const [selectedPeer1Option, setSelectedPeer1Option] = useState(null);
  const [selectedPeer2Option, setSelectedPeer2Option] = useState(null);
  const [optionsList] = useState(['Host Cloud', 'Peer 1', 'Peer 2']);
  const [selectedZonePolicy, setSelectedZonePolicy] = useState(null);
  const [selectedZone, setSelectedZone] = useState(null);
  const [selectedImpact, setSelectedImpact] = useState([]);
  const [selectedImpactItsm, setSelectedImpactItsm] = useState([]);
  const [isCreatingPolicy, setIsCreatingPolicy] = useState(false);
  const generativeAIComplianceKey = "GenerativeAI"
  const [alertData, setAlertData] = useState({
    recipients: null,
    subject: null,
    body: null,
    type: "EMAIL",
  });
  

  function removeSelectedSubCat(givenItem) {
    if (selectedSubCat.includes(givenItem)) {
      const newArray = selectedSubCat.filter((item, index) => item !== givenItem);
      setSelectedSubCat(newArray);
    } else {
      setSelectedSubCat([...selectedSubCat, givenItem])
    }
    console.log(givenItem)
  }

  function removeSelectedItem(givenItem) {
    if (selectedItems.includes(givenItem)) {
      const newArray = selectedItems.filter((item, index) => item !== givenItem);
      setSelectedItems(newArray);
    } else {
      setSelectedItems([...selectedItems, givenItem])
    }
    console.log(givenItem)
  }

  function removeActionSelectedItem(givenItem) {
    if (actionSelectedItems.includes(givenItem)) {
      const newArray = actionSelectedItems.filter((item, index) => item !== givenItem);
      setActionSelectedItems(newArray);
    } else {
      setActionSelectedItems([...actionSelectedItems, givenItem])
    }
    console.log(givenItem)
  }

  function removeEnforcementSelectedItem(givenItem) {
    if (enforcementSelectedItems.includes(givenItem)) {
      const newArray = enforcementSelectedItems.filter((item, index) => item !== givenItem);
      setEnforcementSelectedItems(newArray);
    } else {
      setEnforcementSelectedItems([...enforcementSelectedItems, givenItem])
      if (givenItem === 'Enforce Policy - Time bound') {
        onTimeBoundOpen()
      } else if (givenItem === 'Accept/Transfer Risk') {
        onOpen();
      } else if (givenItem === 'Issue a Ticket (ITSM)') {
        onIssueOpen();
      } else if (givenItem === 'Generate Alert/Sent Email') {
        onEmailOpen();
      } else {

      }
    }
    console.log(givenItem)
  }


  useEffect(() => {
    getSupportedVendorsList();
    getZones();

    console.log(generativeAICategories);
  }, [])



  const getSupportedVendorsList = async () => {
    let supportedVendors = await fetchSupportedVendors((errorMsg) => {
      toast({
        position: 'bottom-left',
        isClosable: true,
        duration: 5000,
        status: 'error',
        description: errorMsg,
      });
    });
    setSupportedVendors(supportedVendors);
    return supportedVendors;
  };



  const handleSelectedImpact = e => {
    const check = e.target.checked;
    const checked = e.target.value;

    if (check) {
      setSelectedImpact(prev => [...prev, checked]);
    } else {
      setSelectedImpact(selectedImpact.filter(d => d !== checked));
    }
  };

  const handleSelectedImpactItsm = e => {
    const check = e.target.checked;
    const checked = e.target.value;

    if (check) {
      setSelectedImpactItsm(prev => [...prev, checked]);
    } else {
      setSelectedImpactItsm(selectedImpact.filter(d => d !== checked));
    }
  };

  const savePolicy = async () => {
    try {
      setIsLoading(true)

      let generativeAISubCategories = [];
      let subcatg = null;
      for (var e in selectedSubCat) {
        subcatg = generativeAICategories[selectedDesireOption.index].items.find((v) => v.name == selectedSubCat[e])

        if (subcatg) {
          generativeAISubCategories.push(subcatg.key)
        }
      }

      let supportedVendor = getSupportedVendorById(selectedSupportedVendor.id, supportedVendors)

      let vendorConfigrations = await fetchVendorConfigurations((errorMsg) => {
        toast({
          position: 'bottom-left',
          isClosable: true,
          duration: 2500,
          status: 'error',
          description: errorMsg,
        });
      })

      let cloudId = getCloudOptionId(selectedCloudOption);
      let vendorConfigurationId = null;
      if (vendorConfigrations && vendorConfigrations.configurations[cloudId]) {

        let config = vendorConfigrations.configurations[cloudId];
        if (config.supportedVendorId == selectedSupportedVendor.id) {
          vendorConfigurationId = config.id;
        } else {
          throw new Error("Vendor Configuration not found against '" + selectedCloudOption + "'")
        }

      } else {
        throw new Error("Vendor Configuration not found against '" + selectedCloudOption + "'")
      }

      // todo
      let accessToken = localStorage.getItem('accessToken');

      // todo allow clearing timeBoundCronVal, delete or persist the email and itsm details
      let data = {
        name: policyName,
        description: policyDescription,
        zoneId: selectedZone,
        tenantId: orgId,
        hosts: [supportedVendor.key],
        desiredOutcomes: [generativeAICategories[selectedDesireOption?.index]?.key],
        solutions: enforcementSelectedItems,
        catogories: generativeAISubCategories,
        actions: actionSelectedItems,
        policyEnforceAction: {cronExpression: timeBoundCronVal, endDateTime: timeBoundDateTime},
        alertAction:  { ...alertData, impacts: selectedImpact },
        itsmAction: { ...assignData, impacts: selectedImpactItsm },
        alertActionLevels: [],
        configurationId: vendorConfigurationId,
        complianceKey: generativeAIComplianceKey,
        accessToken: accessToken
      };


      let savePolicyResponse = await POST(`api/policy/discovery-risk`, data);
      
      if (Object.keys(savePolicyResponse).length > 0) {
        throw new Error("Failed to save policy")
      } else {
        toast({
          position: 'bottom-left',
          isClosable: true,
          duration: 5000,
          status: 'success',
          description: 'Policy has been saved',
        });
        setIsLoading(false)
      }
    } catch (err) {
      setIsLoading(false)
      toast({
        position: 'bottom-left',
        isClosable: true,
        duration: 5000,
        status: 'error',
        description: err.message,
      });
    }
    return;
  }


  const resetState = () => {
    console.log("Resetting state..")
    setGenerativeAICategories([]);
    setSelectedDesireOption({});
    setSelectedItems([]);
    setSelectedSubCat([])
    setEnforcementSelectedItems([])
    setActionSelectedItems([])
  }

  const fetchGenerativeAICategories = async (id, name) => {
    resetState();
    if (localStorage.getItem('accessToken')) {
      try {

        let vendor = getSupportedVendorById(id, supportedVendors)
        const res = await GET(`api/template/compliance/vendor/` + vendor.key);
        if (res?.success) {

          let genAICompliance = res.data.compliance.find((it) => it.key == generativeAIComplianceKey);

          if (genAICompliance == null || genAICompliance === undefined) {
            throw new Error("GenerativeAI compliance not found against " + selectedCloudOption);
          }

          let items = genAICompliance.items;
          setGenerativeAICategories(items);
          console.log(items);

        } else {
          throw new Error(res?.message);
        }

      } catch (error) {
        toast({
          position: 'bottom-left',
          isClosable: true,
          duration: 5000,
          status: 'error',
          description: error.message,
        });
        return;
      }

    } else {

      //Handle the case where there's no access token
      toast({
        position: 'bottom-left',
        isClosable: true,
        duration: 5000,
        status: 'error',
        description: 'Access token is missing',
      });

      return;
    }
  };
  const getZones = async () => {
    if (localStorage.getItem('accessToken')) {
      try {
        let res = await GET(`api/v1/worker/neighborhood-zone`);
        setZones(res);
      } catch (error) {
        toast({
          position: 'bottom-left',
          isClosable: true,
          duration: 5000,
          status: 'error',
          description: 'Neighbor Relations not found!',
        });
      }
    } else {

    }
  };

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isEmailOpen, onOpen: onEmailOpen, onClose: onEmailClose } = useDisclosure();
  const { isOpen: isTimeBoundOpen, onOpen: onTimeBoundOpen, onClose: onTimeBoundClose } = useDisclosure();
  const { isOpen: isIssueOpen, onOpen: onIssueOpen, onClose: onIssueClose } = useDisclosure();
  const [timeBoundCronVal, setTimeBoundCronVal] = useState('');
  const handleTimeBoundChange = (e) => {
    console.log(e)
    setTimeBoundCronVal(e);
  };
  const [ timeBoundDateTime, setTimeBoundDateTime ] = useState('');

  const serviceNowAuth = useSelector(state => state.serviceNowAuth);
  const hibernatingErrorMessage = "Could not extract response: no suitable HttpMessageConverter found for response type [com.cipherstack.itsm.servicenow.model.servicenow.ServiceNowTableResponse<com.cipherstack.itsm.servicenow.dto.IssueRequest>] and content type [text/html]";

  const serviceNowIssue = async () => {
    setIsLoading(true);
    try {
      let response = await POST(`api/itsm/servicenow/issue`, assignData, { bearerToken: `${serviceNowAuth?.access_token}` });
      console.log(response);
      if (response?.message === hibernatingErrorMessage) {
        toast({
          description: 'Unable to reach servicenow server, Please visit dev. servicenow!',
          status: 'error',
          position: 'top-right',
          duration: 4500,
          isClosable: true,
        })
      } else if (response?.status === 400) {
        toast({
          description: 'Invalid data provided!',
          status: 'error',
          position: 'top-right',
          duration: 3000,
          isClosable: true,
        })
      } else {
        toast({
          description: 'Issue assigned successfully!',
          status: 'success',
          position: 'top-right',
          duration: 3000,
          isClosable: true,
        })
      }
    } catch (err) {
      toast({
        description: err?.message ?? 'Something went wrong in third party integration',
        status: 'error',
        position: 'bottom-left',
        duration: 3000,
        isClosable: true,
      })
    }
    setIsLoading(false)
    onIssueClose();
  }

  return (

    <SideWrapper>
      <Stack w={'full'} margin={'40px 50px 30px 0 !important'}>
        {/* Current Location identifier */}
        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
          <LocationHeader
            optionsList={optionsList}
            followingRoute={[
              selectedHostOption?.name,
              selectedPeer1Option?.name,
              selectedPeer2Option?.name,
            ]}
          />
          <NotificationIcon />
        </Stack>
        {/* Cloud options */}
        <Stack
          direction={'row'}
          alignItems={'center'}
          // flexWrap={"wrap"}
          gap={'10px'}
          // padding={'35px 30px'}
          background={'#fff'}
          borderRadius={'10px'}
          marginBottom={'20px !important'}
          spacing={0}
        >
          {policyCloudOptions.map((v, i) => (
            <CloudOptionButton
              {...v}
              key={i}
              name={v.name}
              selectedCloudOption={selectedCloudOption}
              setSelectedCloudOption={setSelectedCloudOption}
            />
          ))}
        </Stack>
        {
          selectedCloudOption === 'Policies' ?
            <SpacedStack>
              <Stack direction="row" justifyContent={"space-between"} alignItems={"center"}>
                <Heading fontSize={'22px'} margin={'0 0 10px 0'}>
                  Policies
                </Heading>
                <Stack direction="row" gap="10px" alignItems="center">
                  <Button
                    // isLoading={isLoading}
                    w={'190px'}
                    bgColor={'#EBF4FF'}
                    color={'#1E86FF'}
                    padding={'22px 0'}
                    onClick={() => setIsCreatingPolicy(!isCreatingPolicy)}
                    fontSize={'16px'}
                    fontWeight={'500'}
                    border={'2px solid #1E86FF'}
                    _hover={{
                      bgColor: '#1E86FF',
                      color: '#fff',
                    }}
                    mr={'10px'}
                  >Create Policy</Button>
                  <Menu>
                    <Text fontSize="16px" fontWeight="600">Zone Specific Policy</Text>
                    <MenuButton
                      as={Button}
                      rightIcon={<ChevronDownIcon />}
                      w={'190px'}
                      bgColor={'#fff'}
                      color={'#D0D5DD'}
                      padding={'22px 0'}
                      // onClick={() => submitUpdateConfig()}
                      fontSize={'16px'}
                      fontWeight={'500'}
                      border={'2px solid #D0D5DD'}
                      _hover={{
                        bgColor: '#D0D5DD',
                        color: '#D0D5DD',
                      }}
                    >
                      {selectedZonePolicy ?? 'Select Zone'}
                    </MenuButton>
                    <MenuList>
                      {
                        zones?.length > 0 &&
                        zones?.map(val =>
                          <MenuItem onClick={() => setSelectedZonePolicy(val?.name)}>{val?.name}</MenuItem>
                        )
                      }
                    </MenuList>
                  </Menu>
                </Stack>
              </Stack>
              <CreatePolicy />
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                mt={'20px !important'}
              >
                <Box>
                  <Text fontWeight="600">Showing 1 to 6 of 6 entries</Text>
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  gap="15px"
                >
                  <Text>Previous</Text>
                  <Text bgColor="#555555" color="#fff" padding="5px 12px" borderRadius="4px">1</Text>
                  <Text padding="5px 10px">2</Text>
                  <Text padding="5px 10px">3</Text>
                  <Text>Next</Text>
                </Box>
              </Stack>
            </SpacedStack>
            :
            <>
              <SpacedStack>
                {selectedCloudOption === 'Host Cloud' ? (
                  <>
                    <Heading fontSize={'22px'} margin={'0 0 10px 0'}>
                      Host Cloud
                    </Heading>
                    <Stack
                      direction={'row'}
                      alignItems={'center'}
                      gap={'10px'}
                      flexWrap={'wrap'}
                      spacing={0}
                    >
                      {supportedVendors?.length > 0 &&
                        supportedVendors?.map((v, i) => (
                          <HostCloudButton
                            {...v}
                            key={i}
                            findData={fetchGenerativeAICategories}
                            selectedCloudOption={selectedSupportedVendor}
                            setSelectedCloudOption={setSelectedSupportedVendor}
                          />
                        ))}

                    </Stack>

                  </>
                ) : selectedCloudOption === 'Peer 1' ? (
                  <>
                    <Heading fontSize={'22px'} margin={'0 0 10px 0'}>
                      Peer 1
                    </Heading>
                    <Stack
                      direction={'row'}
                      alignItems={'center'}
                      gap={'10px'}
                      flexWrap={'wrap'}
                      spacing={0}
                    >
                      {supportedVendors?.length > 0 &&
                        supportedVendors?.map((v, i) => (
                          <HostCloudButton
                            {...v}
                            key={i}
                            findData={fetchGenerativeAICategories}
                            selectedCloudOption={selectedSupportedVendor}
                            setSelectedCloudOption={setSelectedSupportedVendor}
                          />
                        ))}

                    </Stack>
                  </>
                ) : selectedCloudOption === 'Peer 2' ? (
                  <>
                    <Heading fontSize={'22px'} margin={'0 0 10px 0'}>
                      Peer 2

                    </Heading>
                    <Stack
                      direction={'row'}
                      alignItems={'center'}
                      gap={'10px'}
                      flexWrap={'wrap'}
                      spacing={0}
                    >
                      {supportedVendors?.length > 0 &&
                        supportedVendors?.map((v, i) => (
                          <HostCloudButton
                            {...v}
                            key={i}
                            findData={fetchGenerativeAICategories}
                            selectedCloudOption={selectedSupportedVendor}
                            setSelectedCloudOption={setSelectedSupportedVendor}
                          />
                        ))}

                    </Stack>
                  </>
                ) : (
                  <>
                  </>
                )
                }
              </SpacedStack>

              <Text lineHeight={"48px"} fontWeight={"700"} fontSize={"30px"} pl={'30px'}>Policy Creator</Text>
              <Stack
                direction={'row'}
                alignItems={'center'}
                padding={'15px 30px 35px'}
                background={'#fff'}
                borderRadius={'10px'}
                spacing={0}
                gap="40px"
                flexWrap={'wrap'}
              >
                <Box flex="1">
                  <Typography.Text className="formLabel">Name</Typography.Text>
                  <Input key={"policyName"} onChange={(e) => setPolicyName(e.target.value)} />
                </Box>
                <Box flex="1">
                  <Typography.Text className="formLabel">Zone</Typography.Text>
                  <Select
                    _focusVisible={{ outline: 'none' }}
                    border={'1px solid #d9d9d9'}
                    borderRadius={8}
                    onChange={e => setSelectedZone(e.target.value)}
                  >
                    <option style={{ display: 'none' }} value={''}>Select</option>
                    {
                      zones?.length > 0 &&
                      zones?.map(val =>
                        <option key={val?.id} value={val?.name}>{val?.name}</option>
                      )
                    }
                  </Select>
                </Box>
              </Stack>
              <Stack
                direction={'row'}
                alignItems={'center'}
                padding={'15px 30px 35px'}
                background={'#fff'}
                borderRadius={'10px'}
                marginBottom={'20px !important'}
                spacing={0}
                gap="40px"
                flexWrap={'wrap'}
              >
                <Box flex="1">
                  <Typography.Text className="formLabel">Description</Typography.Text>
                  <Input key={"policyDescription"} onChange={(e) => setPolicyDescription(e.target.value)} />
                </Box>
              </Stack>
              {/* <Stack
                direction={'row'}
                alignItems={'center'}
                gap={'10px'}
                padding={'15px 30px 35px'}
                background={'#fff'}
                borderRadius={'10px'}
                marginBottom={'20px !important'}
                spacing={0}
                flexWrap={'wrap'}
              >
                <Text w={'100%'} lineHeight={"48px"} fontWeight={"700"} fontSize={"20px"}>Generative/AI Risk Categories</Text>
                {genPolicyData.map(v => (
                  <DesiredButton
                    {...v}
                    selectedDesireOption={selectedGenRiskOption}
                    setSelectedDesireOption={setSelectedGenRiskOption}
                  />
                ))}
              </Stack> */}
              {
                generativeAICategories.length > 0 &&
                <Stack
                  direction={'row'}
                  alignItems={'center'}
                  gap={'10px'}
                  padding={'15px 30px 35px'}
                  background={'#fff'}
                  borderRadius={'10px'}
                  marginBottom={'20px !important'}
                  spacing={0}
                  flexWrap={'wrap'}
                >
                  <Text w={'100%'} lineHeight={"48px"} fontWeight={"700"} fontSize={"20px"}>Enterprise AI Risk Categories</Text>
                  {generativeAICategories.map((v, i) => (
                    <DesiredButton
                      {...v}
                      selectedDesireOption={selectedDesireOption}
                      setSelectedDesireOption={setSelectedDesireOption}
                      index={i}
                    />
                  ))}
                </Stack>
              }
              {
                selectedDesireOption.index > -1 &&
                <Stack
                  direction={'row'}
                  alignItems={'center'}
                  gap={'10px'}
                  padding={'15px 30px 35px'}
                  background={'#fff'}
                  borderRadius={'10px'}
                  marginBottom={'20px !important'}
                  spacing={0}
                  flexWrap={'wrap'}
                >
                  <Text w={'100%'} lineHeight={"48px"} fontWeight={"700"} fontSize={"20px"}>Enterprise AI Risk Sub-Categories</Text>
                  {

                    generativeAICategories[selectedDesireOption.index].items.map((v, i) => (
                      <SolutionSubOptBox key={i} customName={v.name} selectedItems={selectedSubCat} removeSelectedItem={removeSelectedSubCat} children={true} />
                    ))}
                </Stack>
              }
              {
                selectedSubCat.length > 0 &&
                <>

                  <SpacedStack
                    direction={'row'}
                    alignItems={'center'}
                    gap={'10px'}
                    flexWrap={'wrap'}
                    spacing={0}
                  >
                    <Text color={"black"} fontWeight={"700"} lineHeight={"48px"} fontSize={"22px"} w="100%">Policy Action</Text>
                    {
                      policyAction.map((v, i) => (
                        <SolutionSubOptBox key={i} {...v} selectedItems={actionSelectedItems} removeSelectedItem={removeActionSelectedItem} />
                      ))
                    }
                  </SpacedStack>
                  <Stack w={'full'}>
                    <SpacedStack
                      direction={'row'}
                      alignItems={'center'}
                      gap={'10px'}
                      flexWrap={'wrap'}
                      spacing={0}
                    >
                      <Text w={'100%'} color={"black"} fontWeight={"700"} lineHeight={"48px"} fontSize={"22px"}>Policy Enforcement</Text>
                      {Enforcement.map((v, i) => (
                        <SolutionSubOptBox key={i} {...v} selectedItems={enforcementSelectedItems} removeSelectedItem={removeEnforcementSelectedItem} />
                      ))
                      }
                    </SpacedStack>
                  </Stack>
                  <Stack
                    direction={'row'}
                    alignItems={'center'}
                    justifyContent={'flex-end'}
                  >
                    <Button
                      isLoading={isLoading}
                      w={'190px'}
                      bgColor={'primary.100'}
                      color={'#fff'}
                      padding={'22px 0'}
                      onClick={() => savePolicy()}
                      fontSize={'16px'}
                      fontWeight={'500'}
                      border={'2px solid #4A4A4A'}
                      _hover={{
                        bgColor: 'transparent',
                        color: '#4A4A4A',
                      }}
                    >
                      Save
                    </Button>
                    <Button
                      w={'190px'}
                      bgColor={'transparent'}
                      color={'#8A959E'}
                      padding={'22px 0'}
                      fontSize={'16px'}
                      fontWeight={'500'}
                      border={'2px solid transparent'}
                      _hover={{
                        borderColor: '#4A4A4A',
                        color: '#4A4A4A',
                      }}
                    >
                      Activate
                    </Button>
                  </Stack>
                </>
              }

            </>
        }
      </Stack>
      {/* Time-Bound Modal */}
      <Modal
        isOpen={isTimeBoundOpen}
        onClose={onTimeBoundClose}
        size={'xl'}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Enforce Time-Bound Policy</ModalHeader>
          {/* <ModalCloseButton /> */}
          <ModalBody>
          <Input type="datetime-local"
          onChange={(e) => this.setTimeBoundDateTime(e.target.value)} />
            <Cron
              onChange={handleTimeBoundChange}
              value={timeBoundCronVal}
              showResultText={true}
              showResultCron={true}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={onTimeBoundClose}
              bgColor={'#4A4A4A'}
              color={'#fff'}
              padding={'12px'}
              fontSize={'16px'}
              fontWeight={'500'}
              border={'2px solid #4A4A4A'}
              _hover={{
                bgColor: 'transparent',
                cursor: 'pointer',
                color: '#4A4A4A',
              }}
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={isOpen} onClose={onClose} size={'lg'} padding={'40px'}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Warning</ModalHeader>
          <ModalBody>
            <Text fontFamily={"Inter"} fontSize={'16px'} colo={'#667085'} marginBottom={'20px'}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore </Text>
            <Box
              border={'1px solid #adadad'}
              padding={'10px'}
              cursor={'pointer'}
            >
              <Text fontSize={'15px'} fontFamily={'Inter'}>Select rule info</Text>
              <Select
                cursor={'pointer'}
                border={'none'}
                _focusVisible={{ outline: 'none' }}
              >
                <option>Malicious</option>
                <option>As Per Design</option>
                <option>Accept Risk</option>
              </Select>
            </Box>
            <Stack
              mt={'30px !important'}
              direction={'row'}
              alignItems={'center'}
              justifyContent={'flex-end'}
            >
              <Button
                w={'190px'}
                bgColor={'#4A4A4A'}
                color={'#fff'}
                padding={'22px 0'}
                fontSize={'16px'}
                fontWeight={'500'}
                border={'2px solid #4A4A4A'}
                _hover={{
                  bgColor: 'transparent',
                  color: '#4A4A4A'
                }}
              >Yes</Button>
              <Button
                onClick={() => onClose()}
                w={'80px'}
                bgColor={'transparent'}
                color={'#8A959E'}
                padding={'22px 0'}
                fontSize={'16px'}
                fontWeight={'500'}
                border={'2px solid transparent'}
                _hover={{
                  borderColor: '#4A4A4A',
                  color: '#4A4A4A'
                }}
              >No</Button>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal isOpen={isEmailOpen} onClose={onEmailClose} size={'lg'} padding={'40px'}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Generate Alert/Sent Email</ModalHeader>
          <ModalBody>
            <Box
              border={'1px solid #adadad'}
              padding={'10px'}
              cursor={'pointer'}
            >
              <Typography.Text className="formLabel">Impact</Typography.Text>
              <Box
                padding={'10px'}
                cursor={'pointer'}
              >
                <Box display="flex" gap="10px" mb="5px">
                  <Checkbox value='HIGH' onChange={e => handleSelectedImpact(e)}>High</Checkbox>
                </Box>
                <Box display="flex" gap="10px" mb="5px">
                  <Checkbox value='MEDIUM' onChange={e => handleSelectedImpact(e)}>Medium</Checkbox>
                </Box>
                <Box display="flex" gap="10px" mb="5px">
                  <Checkbox value='LOW' onChange={e => handleSelectedImpact(e)}>Low</Checkbox>
                </Box>
              </Box>
            </Box>
            <Box flex="1">
              <Text fontSize={'15px'} fontFamily={'Inter'}>Recipients</Text>
              <Input onChange={(e) => { 
                    setAlertData({
                    ...alertData, recipients: e.target.value
                    })
                }} />
            </Box>
            <Box flex="1">
              <Text fontSize={'15px'} fontFamily={'Inter'}>Subject</Text>
              <Input onChange={(e) => { 
                    setAlertData({
                    ...alertData, subject: e.target.value
                    })
                }} />
            </Box>
            <Box flex="1">
              <Text fontSize={'15px'} fontFamily={'Inter'}>Body</Text>
              <Input onChange={(e) => { 
                    setAlertData({
                    ...alertData, body: e.target.value
                    })
                }} />
            </Box>
            <Stack
              mt={'30px !important'}
              direction={'row'}
              alignItems={'center'}
              justifyContent={'flex-end'}
            >
              <Button
                onClick={() => onEmailClose()}
                w={'190px'}
                bgColor={'#4A4A4A'}
                color={'#fff'}
                padding={'22px 0'}
                fontSize={'16px'}
                fontWeight={'500'}
                border={'2px solid #4A4A4A'}
                _hover={{
                  bgColor: 'transparent',
                  color: '#4A4A4A'
                }}
              >Save</Button>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal isOpen={isIssueOpen} onClose={onIssueClose} size={'2xl'} padding={'40px'}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Assign Issue(s)</ModalHeader>
          <ModalBody>
            <Text fontFamily={"Inter"} fontSize={'16px'} colo={'#667085'} marginBottom={'20px'}>Issue ServiceNow ticket</Text>
            <Stack
              direction={'row'}
              justifyContent={'space-between'}
              mb={'10px'}
            >
              <Box
                border={'1px solid #adadad'}
                padding={'10px'}
                cursor={'pointer'}
              >
                <Typography.Text className="formLabel">Impact</Typography.Text>
                <Box
                  padding={'10px'}
                  cursor={'pointer'}
                >
                  <Box display="flex" gap="10px" mb="5px">
                    <Checkbox value='HIGH' onChange={e => handleSelectedImpactItsm(e)}>High</Checkbox>
                  </Box>
                  <Box display="flex" gap="10px" mb="5px">
                    <Checkbox value='MEDIUM' onChange={e => handleSelectedImpactItsm(e)}>Medium</Checkbox>
                  </Box>
                  <Box display="flex" gap="10px" mb="5px">
                    <Checkbox value='LOW' onChange={e => handleSelectedImpactItsm(e)}>Low</Checkbox>
                  </Box>
                </Box>
              </Box>
              <Box
                w={'49%'}
                borderRadius={'8px'}

                border={'1px solid #adadad'}
                padding={'10px'}
                cursor={'pointer'}
              >
                <Text fontSize={'15px'} fontFamily={'Inter'}>Description</Text>
                <Input
                  size="md"
                  p={4}
                  borderRadius={6}
                  onChange={e => {
                    setAssignData({
                      ...assignData,
                      description: e.target.value
                    })
                  }}
                />
              </Box>
              <Box
                w={'49%'}
                borderRadius={'8px'}

                border={'1px solid #adadad'}
                padding={'10px'}
                cursor={'pointer'}
              >
                <Text fontSize={'15px'} fontFamily={'Inter'}>Short Description</Text>
                <Input
                  size="md"
                  p={4}
                  borderRadius={6}
                  onChange={e => {
                    setAssignData({
                      ...assignData,
                      short_description: e.target.value
                    })
                  }}
                />
              </Box>
            </Stack>
            <Stack
              direction={'row'}
              justifyContent={'space-between'}
              mb={'10px'}
            >
              <Box
                w={'49%'}
                borderRadius={'8px'}

                border={'1px solid #adadad'}
                padding={'10px'}
                cursor={'pointer'}
              >
                <Text fontSize={'15px'} fontFamily={'Inter'}>Assign to </Text>
                <Input
                  size="md"
                  p={4}
                  borderRadius={6}
                  onChange={e => {
                    setAssignData({
                      ...assignData,
                      assigned_to: e.target.value
                    })
                  }}
                />
                { /* <Select
                  cursor={'pointer'}
                  border={'none'}
                  _focusVisible={{ outline: 'none' }}
                  onChange={e => {
                    setAssignData({
                      ...assignData,
                      assigned_to: e.target.value
                    })
                  }}
                >
                  {
                    serviceNowUsers?.users?.length > 0 ?
                      <option value={''} style={{ display: 'none' }}></option>
                      :
                      <option value={''} style={{ display: 'none' }}>No user found!</option>
                  }
                  {
                    serviceNowUsers?.users?.length > 0 &&
                    serviceNowUsers?.users?.map(val =>
                      <option key={val?.id} value={val?.email}>{val?.username}</option>
                    )
                  }
                </Select> */ 
                }
              </Box>
              <Box
                w={'49%'}
                borderRadius={'8px'}

                border={'1px solid #adadad'}
                padding={'10px'}
                cursor={'pointer'}
              >
                <Text fontSize={'15px'} fontFamily={'Inter'}>Credentials</Text>
                <Input
                  size="md"
                  p={4}
                  borderRadius={6}
                  onChange={e => {
                    setAssignData({
                      ...assignData,
                      credentials: e.target.value
                    })
                  }}
                />
              </Box>
            </Stack>
            <Stack
              mt={'30px !important'}
              direction={'row'}
              alignItems={'center'}
              justifyContent={'flex-end'}
            >
              <Button
                onClick={() => { onIssueClose(); }}
                w={'80px'}
                bgColor={'transparent'}
                color={'#8A959E'}
                padding={'22px 0'}
                fontSize={'16px'}
                fontWeight={'500'}
                border={'2px solid transparent'}
                _hover={{
                  borderColor: '#4A4A4A',
                  color: '#4A4A4A'
                }}
              >Save</Button>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </SideWrapper >
  );
};

export default Policy;
