import {
  Box,
  Icon,
  Image,
  Input,
  Stack,
  Button,
  Heading,
  Select,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Input as CharkInput,
  useToast,
  filter,
} from '@chakra-ui/react';
import { Typography, Form, Divider, Switch } from 'antd';
import {
  MinusSquareOutlined,
  PlusSquareOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { HiOutlineKey } from 'react-icons/hi';
import { AiOutlineIdcard } from 'react-icons/ai';
import { otherCloud } from '../../utilities/cloudOnBoardingData';
import { useRef, useState } from 'react';
import { useEffect } from 'react';
import { PUT } from '../../utilities/ApiProvider';

export default function CloudLogin({ isLoading, submitUpdateConfig, updateConfigValues, listOFConfig, addConfigRow, removeConfigRow, filterData, mainVal, sendData, getMainData, listOFVariables, addVariableRow, removeVariableRow, updateVariableValues, displayFields }) {

  //console.log(filterData)
  const rgnFile = useRef(null);
  const toast = useToast();
  const formatFile = useRef(null);
  const [otherCloudSelect, setOtherCloudSelect] = useState('');
  const [configFileData, setConfigFileData] = useState({
    rgn: '',
    name: '',
    content: '',
  });
  const [variableData, setVariableData] = useState({
    rgn: '',
    format: '',
  });
  const [configData, setConfigData] = useState(null);

  const [userName, setUserName] = useState('');
  const [gcpFile, setGcpFile] = useState({});

  useEffect(() => {
    console.log("use effect")
    setConfigData(filterData);

  }, [filterData])

  const handleUpdateConfigData = (value, target) => {
    configData.credentials[target] = value;
    setConfigData({
      ...configData
    })
  }


  const toggleDiscoveryEnabled = (event, data) => {
    if (configData?.discoveryEnabled == false) {
      configData['discoveryEnabled'] = true

    } else if (configData?.discoveryEnabled == true) {
      configData['discoveryEnabled'] = false
    }

    setConfigData({
      ...configData
    })
  }




  return (

    <Stack w={'100%'}>

      <>
        <Stack>
          <Box w={'full'} className="vendorConfigOpt" display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
            <Heading fontSize={'22px'} color={'#2626262'} margin={'0 0 30px 0'}>
              Add / Update Vendor Configuration
            </Heading>
            {displayFields?.discoveryEnabled &&
              <Box w={"20%"} margin={'0 0 15px 0 !important'}>
                <Typography.Text>Auto Discovery</Typography.Text>
                <Switch
                  size="md"
                  p={4}
                  borderRadius={6}
                  checked={configData?.discoveryEnabled}
                  onChange={e => {
                    toggleDiscoveryEnabled(e)
                  }}
                  style={{marginLeft: '10px'}}
                />
              </Box>
            }
          </Box>
          <Stack
            direction={'row'}
            spacing={0}
            gap={6}
            flexWrap={'wrap'}
            className='vendorConfigFieldsBox'
          >

            {
              displayFields?.username &&
              <Box w={"20%"} margin={'0 0 15px 0 !important'}>
                <Typography.Text>Username</Typography.Text>
                <Input
                  size="md"
                  p={4}
                  borderRadius={6}
                  placeholder="Username"
                  value={configData?.credentials?.username}
                  onChange={e => handleUpdateConfigData(e.target.value, 'username')}
                />
              </Box>
            }
            {
              displayFields?.clientId &&
              <Box w={"25%"} margin={'0 0 15px 0 !important'}>
                <Typography.Text>ClientId / AccessKeyId</Typography.Text>
                <Input
                  size="md"
                  p={4}
                  borderRadius={6}
                  placeholder="123xxx555xxx"
                  value={configData?.credentials?.clientId ?? ''}
                  onChange={(e) => { handleUpdateConfigData(e.target.value, 'clientId') }}
                />
              </Box>
            }
            {
              displayFields?.clientSecret &&
              <Box w={"29%"} margin={'0 0 15px 0 !important'}>
                <Typography.Text>
                  Client Secret Key / Access Key Secret
                </Typography.Text>
                <Input
                  size="md"
                  p={4}
                  borderRadius={6}
                  placeholder="kjuyt67765tgg"
                  value={configData?.credentials?.clientSecret ?? ''}
                  onChange={(e) => { handleUpdateConfigData(e.target.value, 'clientSecret') }}
                />
              </Box>
            }
            {
              displayFields?.tenantId &&
              <Box w={"32.1%"} margin={'0 0 15px 0 !important'}>
                <Typography.Text>Tenant Id</Typography.Text>
                <Input
                  size="md"
                  p={4}
                  borderRadius={6}
                  placeholder="13333558ki8u-00ikxn"
                  value={configData?.credentials?.tenantId ?? ''}
                  onChange={(e) => { handleUpdateConfigData(e.target.value, 'tenantId') }}
                />
              </Box>
            }
            {
              displayFields?.subscriptionId &&
              <Box w={"32.1%"} margin={'0 0 15px 0 !important '}>
                <Typography.Text>Subscription Id</Typography.Text>
                <Input
                  size="md"
                  p={4}
                  borderRadius={6}
                  placeholder="4lkxbb0(kk_@2"
                  value={configData?.credentials?.subscriptionId ?? ''}
                  onChange={(e) => { handleUpdateConfigData(e.target.value, 'subscriptionId') }}
                />
              </Box>
            }

            {
              displayFields?.baseUrl &&
              <Box w={"32.1%"} margin={'0 0 15px 0 !important '}>
                <Typography.Text>Base URL</Typography.Text>
                <Input
                  size="md"
                  p={4}
                  borderRadius={6}
                  placeholder=""
                  value={configData?.credentials?.baseUrl ?? ''}
                  onChange={(e) => { handleUpdateConfigData(e.target.value, 'baseUrl') }}
                />
              </Box>
            }

            {
              displayFields?.accessToken &&
              <Box w={"32.1%"} margin={'0 0 15px 0 !important '}>
                <Typography.Text>Access Token</Typography.Text>
                <Input
                  size="md"
                  p={4}
                  borderRadius={6}
                  placeholder=""
                  value={configData?.credentials?.accessToken ?? ''}
                  onChange={(e) => { handleUpdateConfigData(e.target.value, 'accessToken') }}
                />
              </Box>
            }

            {
              displayFields?.region &&
              <Box w={"20%"} margin={'0 0 15px 0 !important'}>
                <Typography.Text>Region</Typography.Text>
                <Input
                  size="md"
                  p={4}
                  borderRadius={6}
                  placeholder="Default region"
                  value={configData?.credentials?.region}
                  onChange={e => handleUpdateConfigData(e.target.value, 'region')}
                />
              </Box>
            }
          </Stack>
          <Stack margin={'25px 0 !important'} direction={'row'} justifyContent={'space-between'}>
            {displayFields?.variables &&
              <Box w="46%">
                <Box
                  as="span"
                  flex="1"
                  textAlign="left"
                  borderRadius={'6px'}
                  display={'flex'}
                  justifyContent={'space-between'}
                  mb="15px"
                >
                  <Text>Custom Variable</Text>
                  <Icon onClick={addVariableRow} as={PlusSquareOutlined} fontSize="18px" />
                </Box>
                <Stack
                  bgColor={'primary.100'}
                  color={'#fff'}
                  direction={'row'}
                >
                  <Text
                    py={'10px'}
                    w={'30%'}
                    borderRight={'1px solid #E8EDFC'}
                    pl={'20px'}
                  >
                    Variable / Key
                  </Text>
                  <Text py={'10px'} px={'20px'} display={'flex'} justifyContent={'space-between'} alignItems={'center'} w="70%">
                    <Text>Value</Text>
                    {/* <Icon onClick={() => removeVariableRow(ind)} as={MinusSquareOutlined} fontSize="18px" /> */}
                  </Text>
                </Stack>
                {
                  listOFVariables.map((val, ind) => {
                    return (
                      <Stack key={ind}>
                        <Stack
                          direction={'row'}
                          alignItems={'center'}
                          spacing={0}
                        >
                          <Text
                            border={'1px solid #E8EDFC'}
                            py={'20px'}
                            w={'30%'}
                            pl={'20px'}
                          >
                            <Input
                              _focusVisible={{
                                outlineOffset: '0px'
                              }}
                              border={'0 !important'}
                              style={{ width: '82px' }}
                              size="large"
                              name='rgn1'
                              value={val.vairable.key}
                              onChange={e => updateVariableValues(ind, 'key', e.target.value)}
                              placeholder="RGN"
                            />
                          </Text>
                          <Box w={'70%'} border={'1px solid #E8EDFC'} p={'20px 12px'}>
                            <Input
                              _focusVisible={{
                                outlineOffset: '0px'
                              }}
                              border={'0 !important'}
                              style={{ width: '90%' }}
                              size="large"
                              name='rgn1'
                              value={val.vairable.value}
                              onChange={e => updateVariableValues(ind, 'value', e.target.value)}
                            // placeholder={`value${ind === 0 ? '' : (ind > 1 && ind % 2 === 0) ? ind + 2 : ind > 1 ? ind + 1 : ind + 1}`}
                            />
                            <Icon onClick={() => removeVariableRow(ind)} as={MinusSquareOutlined} fontSize="18px" />
                          </Box>
                        </Stack>
                        <Stack
                          direction={'row'}
                          m="0 !important"
                          spacing={0}
                          alignItems={'center'}
                        >
                          <Text
                            border={'1px solid #E8EDFC'}
                            py={'20px'}
                            w={'30%'}
                            pl={'20px'}
                          >
                            <Input
                              _focusVisible={{
                                outlineOffset: '0px'
                              }}
                              border={'0 !important'}
                              style={{ width: '82px' }}
                              size="large"
                              name='rgn1'
                              value={val.vairable.key1}
                              onChange={e => updateVariableValues(ind, 'key1', e.target.value)}
                              placeholder="Format"
                            />
                          </Text>
                          <Box border={'1px solid #E8EDFC'} w={'70%'} p={'20px 12px'}>
                            <Input
                              _focusVisible={{
                                outlineOffset: '0px'
                              }}
                              border={'0 !important'}
                              style={{ width: '90%' }}
                              size="large"
                              value={val.vairable.value1}
                              name='format'
                              onChange={e => updateVariableValues(ind, 'value1', e.target.value)}
                            // placeholder={`value${ind === 0 ? ind + 1 : ind > 1 ? ind + 2 : ind + 2}`}
                            />
                          </Box>
                        </Stack>
                      </Stack>
                    )
                  })
                }
              </Box>
            }
            {displayFields?.configurationFiles &&
              <Box w="46%">
                <Box
                  as="span"
                  flex="1"
                  textAlign="left"
                  borderRadius={'6px'}
                  display={'flex'}
                  justifyContent={'space-between'}
                  mb="15px"
                >
                  <Text>Configuration File Upload</Text>
                  <Icon onClick={addConfigRow} as={PlusSquareOutlined} fontSize="18px" />
                </Box>
                <Stack
                  bgColor={'primary.100'}
                  color={'#fff'}
                  direction={'row'}
                >
                  <Text
                    py={'10px'}
                    w={'30%'}
                    borderRight={'1px solid #E8EDFC'}
                    pl={'20px'}
                  >
                    Name
                  </Text>
                  <Text py={'10px'} px={'20px'} display={'flex'} justifyContent={'space-between'} alignItems={'center'} w="70%">
                    <Text>File</Text>
                    {/* <Icon onClick={() => removeConfigRow(ind)} as={MinusSquareOutlined} fontSize="18px" /> */}
                  </Text>
                </Stack>
                {
                  listOFConfig.map((val, ind) => {
                    console.log(val)
                    return (
                      <Stack
                        key={ind}
                      >

                        <Stack
                          direction={'row'}
                          alignItems={'center'}
                          spacing={0}
                        >
                          <Text
                            border={'1px solid #E8EDFC'}
                            py={'20px'}
                            w={'30%'}
                            pl={'20px'}
                          >

                            Name
                          </Text>
                          <Box w={'70%'} border={'1px solid #E8EDFC'} p={'20px 12px'}>
                            <Input
                              _focusVisible={{
                                outlineOffset: '0px'
                              }}
                              border={'0 !important'}
                              style={{ width: '90%' }}
                              size="large"
                              name='rgn1'
                              value={val?.default?.name}
                              onChange={e => updateConfigValues(ind, 'name', e.target.value)}
                              placeholder="Value 1"
                            />
                            <Icon onClick={() => removeConfigRow(ind)} as={MinusSquareOutlined} fontSize="18px" />
                          </Box>
                        </Stack>
                        {/* <Stack
                        direction={'row'}
                        spacing={0}
                        alignItems={'center'}
                      >
                        <Text
                          border={'1px solid #E8EDFC'}
                          py={'20px'}
                          w={'30%'}
                          pl={'20px'}
                        >

                          RGN
                        </Text>
                        <Box border={'1px solid #E8EDFC'} w={'70%'} p={'20px 12px'}>
                          <Input
                            _focusVisible={{
                              outlineOffset: '0px'
                            }}
                            border={'0 !important'}
                            style={{ width: '82px' }}
                            size="large"
                            value={mainVal?.format}
                            name='format'
                            onChange={getMainData}
                            placeholder="Value 2"
                          />
                        </Box>
                      </Stack> */}
                        <Stack
                          direction={'row'}
                          spacing={0}
                          alignItems={'center'}
                        >
                          <Text
                            border={'1px solid #E8EDFC'}
                            py={'20px'}
                            w={'30%'}
                            pl={'20px'}
                          >

                            Content
                          </Text>
                          <Box border={'1px solid #E8EDFC'} w={'70%'} p={'20px 12px'}>
                            <CharkInput
                              border={'1px solid #E8EDFC'}
                              // style={{ width: '82px', display: 'none' }}
                              size="large"
                              placeholder="Value 2"
                              name='content'
                              type="file"
                              // onChange={e => updateConfigValues(ind, 'content', e.target.files[0])}
                              multiple
                            />
                          </Box>
                        </Stack>
                      </Stack>
                    )
                  })
                }
              </Box>
            }
          </Stack>
        </Stack>
        <Stack
          direction={'row'}
          alignItems={'center'}
          justifyContent={'flex-start'}
        >
          <Button
            w={'190px'}
            bgColor={'primary.100'}
            color={'#fff'}
            padding={'22px 0'}
            onClick={() => submitUpdateConfig()}
            fontSize={'16px'}
            fontWeight={'500'}
            border={'2px solid #4A4A4A'}
            _hover={{
              bgColor: 'transparent',
              color: '#4A4A4A',
            }}
          >
            Save
          </Button>
          <Button
            w={'190px'}
            bgColor={'transparent'}
            color={'#8A959E'}
            padding={'22px 0'}
            fontSize={'16px'}
            fontWeight={'500'}
            border={'2px solid transparent'}
            _hover={{
              borderColor: '#4A4A4A',
              color: '#4A4A4A',
            }}
            onClick={() => submitUpdateConfig()}
          >
            Cancel
          </Button>
        </Stack>
      </>
    </Stack>

  );
}
